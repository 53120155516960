import React, { createContext, useState, useEffect } from 'react';

import api from '../api';

const Context = createContext();

function MarkerProvider({ children }) {
    const [markers, setMarkers] = useState([]);

    const [drawerNodes, setDrawerNodes] = useState([]);
    const [checked, setChecked] = useState([]);
    const [checkedDevices, setCheckedDevices] = useState([]);

    const [map, setMap] = useState(null);

    const [overlayLayers, setOverlayLayers] = useState({});
    const [zoom, setZoom] = useState(10);

    useEffect(() => {
        (async () => {
            try {
                const getMarkers = await api.get('/mapa/lista');
                const newMarkers = getMarkers.data.map(item => {
                    item.hidden = false
                    return item
                });
                setMarkers(newMarkers);

                const getDrawerNodes = await api.get('/usuario/listaEstacoes');
                const stringifyDrawernodes = await JSON.stringify(getDrawerNodes.data);
                setDrawerNodes(JSON.parse(stringifyDrawernodes));
                
            } catch(e) {
                console.log(e);
            }
        })()
    }, []);

    function handleListInteraction(topics) {
        const checkedIds = []
        const checkedDevices = []

        topics.map(item => {
            let topic = item.split('/');
            const id = topic[topic.length - 2];
            const deviceId = topic[topic.length - 1];
            checkedIds.push(id);
            checkedDevices.push(deviceId);
            return null
        });

        const markersList = markers.map(item => {
            if(checkedIds.includes(item.id)) item.hidden = false
            else item.hidden = true
            return item
        });

        setCheckedDevices(checkedDevices);
        setMarkers(markersList);
    }

    function showAllMarkers() {
        const markersList = markers.map(item => {
           item.hidden = false
            return item
        });

        setMarkers(markersList);
        setCheckedDevices([]);
    }

    async function handleDrawerNodesFilter(stationType, stationName, subBasin, city) {
        const stationTypes = []
        const sensorsIds = []
        stationType.map(item => {
            if (item.includes('/')) {
                const split = item.split('/');
                if(!stationTypes.includes(split[0]))
                    stationTypes.push(split[0]);
                sensorsIds.push(split[1]);
            } else {
                stationTypes.push(item);
            }
            return null;
        });
        const { data } = await api.get(`/usuario/listaEstacoes/filtros?tipoEstacao=${stationTypes}&search=${stationName}&idSensor=${sensorsIds}${subBasin.hasOwnProperty('id') ? `&idSubBacia=${subBasin.id}` : ''}${city.hasOwnProperty('id') ? `&idMunicipio=${city.codigoMunicipio}` : ''}`);
        const stringifyNodes = await JSON.stringify(data);
        setDrawerNodes(JSON.parse(stringifyNodes));
    }

    function handleDrawerFilter(e, stationType, stationName, subBasin, city) {
        e.preventDefault();
        handleDrawerNodesFilter(stationType, stationName, subBasin, city);//filtros 
       
        map.eachLayer((layer) => {
            if(layer.hasOwnProperty('wmsParams'))
                map.removeLayer(layer);
        });
        const overlayLayersFilters = {};
        if(subBasin.hasOwnProperty('id')) overlayLayersFilters['sima:A_Sub-Bacias-Paraná'] = { metadata: subBasin, checked: true };
        if(city.hasOwnProperty('id')) overlayLayersFilters['sima:B_Município'] = { metadata: city, checked: true };

        if(subBasin.hasOwnProperty('id') && !city.hasOwnProperty('id')) map.setView([subBasin.latitude, subBasin.longitude], zoom);
        if(city.hasOwnProperty('id') && !subBasin.hasOwnProperty('id')) map.setView([city.latitude, city.longitude], zoom);

        setOverlayLayers(overlayLayersFilters);
    }

    async function handleDrawerClear() {
        try {
            const { data } = await api.get('/usuario/listaEstacoes');
            const nodes = await JSON.stringify(data);
            setDrawerNodes(JSON.parse(nodes));
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <Context.Provider value={{ 
            markers,
            handleListInteraction,
            showAllMarkers,
            checked,
            setChecked,
            checkedDevices,
            handleDrawerFilter,
            handleDrawerClear,
            drawerNodes,
            setMap,
            overlayLayers
        }}>
            {children}
        </Context.Provider>
    );
}

export { Context, MarkerProvider };