import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';

import logo from '../../assets/sobre.png';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 3),
      maxWidth: '500px',
        '& > :first-child': {
            fontWeight: '500',
        },
        '& > :first-child + div': {
            margin: '0.8rem 0',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center',
        },
        '& > :first-child + div + div': {
            textAlign: 'justify',
        },
        '&:focus': {
            outline: 'none !important',
        },
        '& > img': {
            paddingTop: '1rem',
        },
    },
}));

const About = () => {
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(true);

    return (
        <Modal
            aria-labelledby='modal-about'
            aria-describedby='modal-about-description'
            className={classes.modal}
            open={modalOpen}
            onClose={e => setModalOpen(false)}
            closeAfterTransition={false}
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={modalOpen}>
                <Box className={classes.paper}>
                    <div>Sobre o sistema</div>
                    <div>SIMA - Sistema Integrado do Monitoramento Ambiental</div>
                    <div>Fruto de uma parceria entre a Diretoria de Coordenação de Itaipu, Superintendência de Informática, Fundação Parque Tecnológico Itaipu, Iapar - Instituto Agrônomico do Paraná e Unioeste, o objetivo do sistema SIMA é propiciar a integração dos dados distribuídos das estações de monitoramento ambiental e agrometeorológicas , através de tecnologia embarcadas em uma Plataforma de Internet das Coisas.</div>
                    <img src={logo} alt="Logo" />
                </Box>
            </Fade>
        </Modal>
    );
}

export default About;