import React, { useState, useEffect } from 'react';

import TabsPanel from '../tabs-panel/tabs-panel.component';
import UploadCsv from './upload-csv/upload-csv.component';
import UploadList from './upload-list/upload-list.component';

import api from '../../api';

const UploadManager = () => {
    const [userMaxLevel, setUserMaxLevel] = useState(0);

    const [tab, setTab] = useState(0);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get('/usuario/metadados');
                const maxLevelAccess = data.vinculos.map(item => item.perfil.levelaccess).reduce(function(a, b) {
                    return Math.max(a, b);
                });
                setUserMaxLevel(maxLevelAccess);
            } catch(e) {
                console.log(e)
            }
        })();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    }

    return (
        <TabsPanel
            tabsComponents={[
                <UploadCsv title='Upload CSV' show />,
                <UploadList title='Lista de Uploads' show={userMaxLevel === 10 ? true : false} />
            ]}
            handleChangeTab={handleChangeTab}
            tab={tab}
        />
    )

}

export default UploadManager;