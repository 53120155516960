import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select, { components } from 'react-select';

import './multiselect-input.styles.scss';

const colourStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      width: '100%',
      borderRadius: state.menuIsOpen ? '4px 4px 0 0' : 4,
      position: 'relative',
      background: null,
      boxShadow: null,
      backgroundColor: '#F5F5F5',
      borderColor: state.menuIsOpen ? '#14B8AE !important' : '#707070',
      borderWidth: state.menuIsOpen ? '2px 2px 0 2px' : '1px',
      borderStyle: 'solid',
      borderBottom: state.menuIsOpen ? '1px solid #707070 !important' : null,
      padding: state.menuIsOpen ? '9.5px 13px' : '10px 14px',
      ':hover': {
        cursor: 'pointer',
        borderColor: '#707070',
      }
  }},
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    minWidth: null,
    backgroundColor:
        isDisabled
        ? null
        : isSelected
        ? '#BFBFBF'
        : isFocused
        ? 'null'
        : null,
      ':hover': {
        backgroundColor: null,
        cursor: 'pointer',
      },
      ':active': {
        ...styles[':active'],
        backgroundColor: null,
      },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 10,
    margin: '0 !important',
    borderColor: '#14B8AE',
    borderWidth: '0 2px 2px 2px',
    borderStyle: 'solid',
    borderRadius: '0 0 4px 4px',
    boxShadow: null,
    backgroundColor: '#F5F5F5',
  }),
  menuList: styles => ({
    ...styles,
    padding: '0 !important',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      background: '#14B8AE',
    },
    '&::-webkit-scrollbar-track': {
      background: '#707070',
    },
  }),
  input: (styles) => ({
    ...styles,
    fontSize: '1rem',
    '& input': {
      font: 'inherit',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: '1rem',
    maxHeight: '35px',
    overflowY: 'hidden',
  }),
  multiValue: (styles, state) => ({
      ...styles,
      fontSize: '1rem',
  }),
};

const useStyles = makeStyles((theme) => ({
  selectLabel: {
      position: 'absolute',
      top: '-7px',
      left: '8px',
      color: '#3A3A3A',
      fontWeight: 500,
      backgroundColor: theme.palette.secondary.main,
      padding: '0 0.5rem',
      transform: 'translate(0, 1.5px) scale(0.75)',
      '&.open': {
        color: '#14B8AE !important',
      },
  },
}));

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const newChildren = _.clone(children);
  const nbValues = getValue().length;

  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        { children }
      </components.ValueContainer>
    );
  }

 newChildren[0] =  `${nbValues === 1 && getValue()[0].value === '<SELECT_ALL>' ? 'Todos os' : nbValues} valores selecionados`;
  
  return (
    <components.ValueContainer {...props}>
      { newChildren }
    </components.ValueContainer>
  );
};

const MultiselectInput = props => {
  const components = { ValueContainer };
  const [labelZIndex, setLabelZIndex] = useState(2);
  const classes = useStyles();

  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  
  const selectAllOption = {
    value: '<SELECT_ALL>',
    label: 'Todos'
  };
 

  const isSelectAllSelected = () =>
    valueRef.current.length === props.options.length;
    

  const isOptionSelected = option =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();
   

  const getOptions = () => props.allowSelectAll ? [selectAllOption, ...props.options]:[...props.options];

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    

    if (action === 'select-option' && option.value === selectAllOption.value) {
      props.onChange(props.options, actionMeta);
      
    } else if (
      (action === 'deselect-option' &&
        option.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      props.onChange([], actionMeta);
    } else if (
      actionMeta.action === 'deselect-option' &&
      isSelectAllSelected()
    ) {
      props.onChange(
        props.options.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      props.onChange(newValue || [], actionMeta);
    }
    
  };

  return (
    <div className='multiselect-wrapper'>
      <InputLabel 
        className={clsx(classes.selectLabel, {'open' : labelZIndex === 999})}
        style={{ zIndex: labelZIndex }}
      >
          {props.label}
          
      </InputLabel>
      <Select
        {...props}
        components={components}
        styles={colourStyles}
        isOptionSelected={isOptionSelected}
        options={getOptions()}
        value={getValue()}
        onChange={onChange}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isMulti
      />
      
    </div>
  );
};

export default MultiselectInput;