import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {

    return (
        <Box display='flex' justifyContent='center' alignItems='center' height='200px' width='100%'>
            <CircularProgress />
        </Box>
    );

}

export default Loading;