import React, { useState, useContext } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import SelectInput from '../select-input/select-input.component';
import BiologicalFilter from './biological-filter/biological-filter.component';
import QuimicalPhysicalFilter from './quimical-physical-filter/quimical-physical-filter.component';
import './filters-panel.styles.scss';

import api from '../../api';
import { Context } from '../../context/MainContext';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const FiltersPanel = () => {
    const { checked, checkedDevices } = useContext(Context);

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [failureMessage, setFailureMessage] = useState('Por favor, preencha todos os campos.');
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [initialDate, setInitialDate] = useState('');
    const [finalDate, setFinalDate] = useState('');

    const [product, setProduct] = useState('1');
    const [graphic, setGraphic] = useState('line');
    const [dataShowcase, setDataShowcase] = useState('integrals');
    const [scheduleWindow, setScheduleWindow] = useState('keep');

    const [parameters, setParameters] = useState([]);
    const [sampleNature, setSampleNature] = useState([]);

    const [parametersOptions, setParametersOptions] = useState([]);
    const [sampleNatureOptions, setSampleNatureOptions] = useState([]);

    const [dataType, setDataType] = useState('Fitoplâncton');
    const [phylum, setPhylum] = useState([]);
    const [grade, setGrade] = useState([]);
    const [order, setOrder] = useState([]);
    const [depth, setDepth] = useState([]);

    const [phylumOptions, setPhylumOptions] = useState([]);
    const [gradeOptions, setGradeOptions] = useState([]);
    const [orderOptions, setOrderOptions] = useState([]);
    const [depthOptions, setDepthOptions] = useState([]);

    const setBiologicalMultiSelectValues = (parameters) => {
        setPhylumOptions(
            parameters.filos.map((item, index) => {
                return {
                    'id': index,
                    'value': item,
                    'label': item
                }
        }));
        setGradeOptions(
            parameters.classes.map((item, index) => {
                return {
                    'id': index,
                    'value': item,
                    'label': item
                }
        }));
        setOrderOptions(
            parameters.ordens.map((item, index) => {
                return {
                    'id': index,
                    'value': item,
                    'label': item
                }
            }));
        setDepthOptions(
            parameters.profundidades.map((item, index) => {
                return {
                    'id': index,
                    'value': item,
                    'label': item
                }
            })
        );
    }

    const setQuimicalPhysicalMultiSelectValues = (parameters) => {
        setParametersOptions(
            parameters.parametros.map((item, index) => {
                return {
                    'id': index,
                    'value': item,
                    'label': item
                }
        }));
        setSampleNatureOptions(
            parameters.naturezaDaAmostra.map((item, index) => {
                return {
                    'id': index,
                    'value': item,
                    'label': item
                }
        }));
        setDepthOptions(
            parameters.profundidades.map((item, index) => {
                return {
                    'id': index,
                    'value': item,
                    'label': item
                }
            })
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if(!name.trim()) {
            setLoading(false);
            setFailureMessage('Por favor, preencha todos os campos.');
            setFailureOpen(true);
            return;
        }

        let classification = {}

        if(!checkedDevices.some(el => el !== '89')) {
            classification = { 'biological': {
                    'classification': {
                        'phylum': {
                            'value': phylum.map(item => {
                                return item.value
                            })
                        },
                        'order': {
                            'value': order.map(item => {
                                return item.value
                            })
                        },
                        'class': {
                            'value': grade.map(item => {
                                return item.value
                            })
                        }
                    },
                    'depth': {
                        'value': depth.map(item => {
                            return item.value
                        })
                    },
                    'dataType': {
                        'value': [dataType]
                    }
                }
            }
        } else if(!checkedDevices.some(el => el !== '90')) {
            classification = { 'quimfis': {
                    'parameters': {
                        'value': parameters.map(item => {
                            return item.value
                        })
                    },
                    'depth': {
                        'value': depth.map(item => {
                            return item.value
                        })
                    },
                    'dataType': {
                        'value': sampleNature.map(item => {
                            return item.value
                        })
                    }
                }
            }
        }
        
        const data = {
            'nomerequisisao': name,
            'dataInicial': `${moment(initialDate).format('DD/MM/YYYY')} - 00:00`,
            'dataFinal': `${moment(finalDate).format('DD/MM/YYYY')} - 00:00`,
            'dispositivosSelecionados': checked,
            'tipoRequisicao': product,
            'sample': '1',
            'classificadores': classification
        };

        if(product === '1') {
            data.chart_type = '1'
            data.nomeEixoX = 'Data'
            data.nomeEixoY = 'Sensores'
            data.tituloGrafico = name
        }

        try {
            await api.post('/job/newJob', JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            setLoading(false);
            setSuccessOpen(true);
        } catch(e) {
            setLoading(false);
            if(e.response.data) setFailureMessage(e.response.data);
            setFailureOpen(true);
        }
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
          return;
    
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    };

    const handleDataType = async (e) => {
        setDataType(e.target.value);//fitoplancton ou zooplancton
        
        
        try {
            if(e.target.value === 'Fitoplâncton') {
                const request = await api.get('/instituicao/filtro/4');
                const parameters = await JSON.parse(request.data.parametros);
                setBiologicalMultiSelectValues(parameters);
                
                
            } else {
                const request = await api.get('/instituicao/filtro/3');
                const parameters = await JSON.parse(request.data.parametros);
                setBiologicalMultiSelectValues(parameters);
            }
        } catch(e) {
            console.log(e.response.data);
        }

    }

    return (
        <div style={{ width: '100%' }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={8}>
                        <TextField 
                            id='request'
                            label='Título da requisição'
                            placeholder='Exemplo'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setName(e.target.value)}
                            inputProps={{ maxLength: 30 }}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3} md={2}>
                        <TextField 
                            id='date-initial'
                            label='Data inicial'
                            type='date'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setInitialDate(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3} md={2}>
                        <TextField 
                            id='date-final'
                            label='Data final'
                            type='date'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setFinalDate(e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3} md={2}>
                        <SelectInput
                            label='product'
                            labelTitle='Produto'
                            values={[{id: '1', name: 'Gráfico Web'}, {id: '2', name: 'Arquivo CSV'}, {id: '3', name: 'Arquivo XLSX'}]}
                            default='1'
                            value={product}
                            handleChange={e => setProduct(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3} md={2}>
                        <SelectInput
                            label='graphic'
                            labelTitle='Gráfico'
                            values={[{id: 'line', name: 'Gráfico de linha'}]}
                            default='line'
                            value={graphic}
                            handleChange={e => setGraphic(e.target.value)}
                            disabled={product === 1 ? false : true}
                        />
                    </Grid>

                    <Grid item xs={6} sm={3} md={2}>
                        <SelectInput
                            label='data-showcase'
                            labelTitle='Amostragem de dados'
                            values={[{id: 'integrals', name: 'Valores integrais'}]}
                            default='integrals'
                            value={dataShowcase}
                            handleChange={e => setDataShowcase(e.target.value)}
                        />
                        
                    </Grid>

                    <Grid item xs={6} sm={3} md={2}>
                        <SelectInput
                            label='schedule-window'
                            labelTitle='Janela de agendamento'
                            values={[{id: 'keep', name: 'Manter de acordo com o dado armazenado'}]}
                            default='keep'
                            value={scheduleWindow}
                            handleChange={e => setScheduleWindow(e.target.value)}
                        />
                    </Grid>
                </Grid>

                { !checkedDevices.some(el => el !== '89') ?
                    <BiologicalFilter
                        dataType={dataType}
                        handleDataType={handleDataType}
                        phylumOptions={phylumOptions}
                        setPhylum={setPhylum}
                        phylum={phylum}
                        gradeOptions={gradeOptions}
                        setGrade={setGrade}
                        grade={grade}
                        orderOptions={orderOptions}
                        setOrder={setOrder}
                        order={order}
                        depthOptions={depthOptions}
                        setDepth={setDepth}
                        depth={depth}
                        setBiologicalMultiSelectValues={setBiologicalMultiSelectValues}
                        
                    /> : false
                }
                

                { !checkedDevices.some(el => el !== '90') ?
                    <QuimicalPhysicalFilter
                        parametersOptions={parametersOptions}
                        setParameters={setParameters}
                        parameters={parameters}
                        depthOptions={depthOptions}
                        setDepth={setDepth}
                        depth={depth}
                        sampleNatureOptions={sampleNatureOptions}
                        setSampleNature={setSampleNature}
                        sampleNature={sampleNature}
                        setQuimicalPhysicalMultiSelectValues={setQuimicalPhysicalMultiSelectValues}
                    /> : false
                }
             
                <div className='button-wrapper multiples'>
                    <Button variant='contained' color='secondary'>
                        RESETAR
                    </Button>
                    <Button variant='contained' color='primary' type='submit' >
                        REQUISITAR
                    </Button>
                </div>
            </form>
            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    Requisição realizada com sucesso!
                </Alert>
            </Snackbar>

            <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='error'>
                    {failureMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='info'>
                    <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                </Alert>
            </Snackbar>
        </div>
    );
}

export default FiltersPanel;