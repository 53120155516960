import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';


const StyledTabs = withStyles((theme) => ({
    root: {
        minHeight: 'auto',
        borderBottom: '1px solid ' + theme.palette.secondary.contrastText,
    },
    indicator: {
      display: 'none',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.primary.contrastText,
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(16),
        marginRight: theme.spacing(1),
        backgroundColor: '#BFBFBF',
        opacity: 1,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        padding: '0.2rem 0.75rem !important',
        minHeight: 'auto',
        minWidth: 'auto',
        '&:focus': {
            
        },
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
    }
}))((props) => <Tab disableRipple {...props} />);
  
const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxHeight: '450px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      [theme.breakpoints.up('sm')]: {
        maxHeight: '550px',
        overflowX: 'hidden',
      },
      [theme.breakpoints.up('md')]: {
        maxHeight: 'none',
        overflow: 'unset',
      },
    },
    padding: {
      padding: theme.spacing(3),
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    selectLabel: {
        position: 'absolute',
        top: '-8px',
        left: '8px',
        zIndex: 2,
        color: '#3A3A3A',
        fontWeight: 500,
        backgroundColor: theme.palette.secondary.main,
        padding: '0 0.5rem',
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box marginTop={2}>
            {children}
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const TabsPanel = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <StyledTabs value={props.tab} onChange={props.handleChangeTab} aria-label="tabs">
                  {props.tabsComponents.map((component, index) => (
                    component.props.show ? <StyledTab key={index} label={component.props.title} /> : ''
                  ))}
                </StyledTabs>
            </div>
            {props.tabsComponents.map((component, index) => (
                <TabPanel key={index} value={props.tab} index={index}>
                  {component.props.show ? component : ''}
                </TabPanel>
            ))}
        </div>
    );

}

export default TabsPanel;
