import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDropzone } from 'react-dropzone';

import {ReactComponent as ArrowDownIcon} from '../../../assets/upload/arrow-down-s-fill.svg';

import './upload-csv.styles.scss';

import api from '../../../api';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const UploadCsv = () => {

    const [institutionList, setInstitutionList] = useState([]);
    const [institutionAreaList, setInstitutionAreaList] = useState([]);
    const [applicationAreaList, setApplicationAreaList] = useState([]);
    const [contractList, setContractList] = useState([]);

    const [institution, setInstitution] = useState({});
    const [institutionArea, setInstitutionArea] = useState({});
    const [applicationArea, setApplicationArea] = useState(0);
    const [contract, setContract] = useState(0);

    const [deviceList, setDeviceList] = useState([]);

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [alertError, setAlertError] = useState('Por favor, verifique se todos os campos estão preenchidos corretamente.');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const getInstitutionList = await api.get('/instituicao/usuario');
                setInstitutionList(getInstitutionList.data);

                const getContractList = await api.get('/importar/contrato/lista');
                setContractList(getContractList.data);
            } catch(e) {
                console.log(e);
            }
        })();
    }, []);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
    
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    }

    const Dropzone = () => {
        const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({    
            maxFiles: 1, accept: '.csv'
        });
        
        const files = acceptedFiles.map(file => (
            <div key={file.path} style={{ marginTop: '0.5rem' }}>{file.path} - {file.size} bytes</div>
        ));

        const fileRejectionItems = fileRejections.map(({ file, errors  }) => { 
            return (
                <div key={file.path} style={{ marginTop: '0.5rem' }}>
                   {file.path} - {file.size} bytes - {errors.map(e => <div key={e.code}>{e.message}</div>)}
                </div>
            );
        });
      
        return (
          <section>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input id='csv-file' {...getInputProps()} />
                <div className='dropzone-text'>
                    <Typography component='h2' className='dropzone-h2'>Arraste o arquivo CSV ou click para selecionar o arquivo</Typography>
                    {files}
                    {fileRejectionItems}
                </div>
            </div>
          </section>
        );
    }

    const handleInstitutionChange = async (event, newValue) => {
        setInstitutionArea({});
        setApplicationArea({});
        if(!newValue) {
            setInstitution({});
            setInstitutionAreaList([]);
            setApplicationAreaList([]);
            return;
        }

        setInstitution(newValue);
        try {
            const { data } = await api.get(`/instituicao/areas/${newValue.id}`);
            setInstitutionAreaList(data);
        } catch(e) {
            console.log(e);
        }
    }

    const handleInstitutionAreaChange = async (event, newValue) => {
        setApplicationArea({});
        if(!newValue) {
            setInstitutionArea({});
            setApplicationAreaList([]);
            return;
        }
        setInstitutionArea(newValue);

        try {
            const { data } = await api.get(`/instituicao/areas/aplicacao/${institution.id}/${newValue.areaid.id}`);
            setApplicationAreaList(data);
        } catch(e) {
            console.log(e);
        }
    }

    const handleApplicationAreaChange = (event, newValue) => {
        if(!newValue) return setApplicationArea({});
        setApplicationArea(newValue);
    }

    const handleContractChange = (event, newValue) => {
        if(!newValue) { 
            setDeviceList([]);
            return setContract(0)
        }
        setContract(newValue.id);
        setDeviceList(newValue.contrato);
    }

    const handleUpload = async (event) => {
        setLoading(true);
        const formData = new FormData();
        const csvFile = document.querySelector('#csv-file');
        try {
            formData.append('arquivo', csvFile.files[0]);
            formData.append('idContrato', contract);
            formData.append('idArea', JSON.stringify({ id: applicationArea.areaDeAplicacaoid.id, area_institution_id: institutionArea.areaid.id, institution_id: institution.id }));
            await api.post('/importar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setLoading(false);
            setSuccessOpen(true);
        } catch(e) {
            setLoading(false);
            if(csvFile.files.length === 0) setAlertError('É necessário ser feito o upload de um arquivo.');
            else if(e.hasOwnProperty('response')) setAlertError(e.response.data);
            else setAlertError('Verifique se todos os campos estão preenchidos corretamente!');
            setFailureOpen(true);
        }
    }
    
    return (
        <Box>
            <Box display='flex' justifyContent='space-between' margin='1.5rem -0.5rem 1rem -0.5rem'>
                <Box flexGrow={1} margin='0 0.5rem'>
                    <Autocomplete
                        disablePortal
                        id='institution'
                        options={institutionList}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                placeholder='Instituição'
                                variant='outlined'
                                label='Instituição'
                            />
                        )}
                        getOptionLabel={(option) => option.nome || ''}
                        onChange={handleInstitutionChange}
                        value={institution}
                    />
                </Box>
                <Box flexGrow={1} margin='0 0.5rem'>
                    <Autocomplete
                        disablePortal
                        id='areaInstitution'
                        options={institutionAreaList}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                placeholder='Área da Instituição'
                                variant='outlined'
                                label='Área da Instituição'
                            />
                        )}
                        getOptionLabel={(option) => option.nome || ''}
                        onChange={handleInstitutionAreaChange}
                        value={institutionArea}
                    />
                </Box>
            </Box>

            <Box display='flex' justifyContent='space-between' margin='1rem -0.5rem -0.5rem'>
                <Box flexGrow={1} margin='0 0.5rem'>
                    <Autocomplete
                        disablePortal
                        id='areaApplication'
                        options={applicationAreaList}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                placeholder='Área da Aplicação'
                                variant='outlined'
                                label='Área da Aplicação'
                            />
                        )}
                        getOptionLabel={(option) => option.nome || ''}
                        onChange={handleApplicationAreaChange}
                        value={applicationArea}
                    />
                </Box>
                <Box flexGrow={1} margin='0 0.5rem'>
                    <Autocomplete
                        disablePortal
                        id='contract'
                        options={contractList}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                placeholder='Contrato'
                                variant='outlined'
                                label='Contrato'
                            />
                        )}
                        getOptionLabel={(option) => option.descricao || ''}
                        onChange={handleContractChange}
                    />
                </Box>
            </Box>
            <Dropzone />
            <Box display='flex' flexDirection='column'>
                <Box display='flex' flexDirection='row' alignItems='center' fontSize='1.25rem'>
                    <ArrowDownIcon fill='currentColor' style={{ color: '#707070', height: '2rem', width: '2rem' }} />
                    Sensores ({deviceList.length})
                </Box>
                <Box padding='0 1rem 0 1rem' margin='0.5rem 0 1.5rem 0' style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: '5rem'}}>
                    <Grid container >
                        {deviceList.map(item =>
                            <Grid key={item} item xs={6} sm={3} md={2}>
                                {item}
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            <Box display='flex' justifyContent='end'>
                <Button onClick={handleUpload} variant='contained' color='primary'>
                    UPLOAD
                </Button>
            </Box>

            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    O upload foi realizado com sucesso!
                </Alert>
            </Snackbar>

            <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='error'>
                    {alertError}
                </Alert>
            </Snackbar>

            <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} style={{zIndex: '9999'}}>
                <Alert onClose={handleAlertClose} severity='info'>
                    <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default UploadCsv;