import React, { useState } from 'react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {ReactComponent as DeleteIcon} from '../../assets/admin/delete-bin-5-fill.svg';
import { Replay } from '@material-ui/icons';

import api from '../../api';

const headCells = [
    { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Nome' },
    { id: 'initial_date', align: 'center', numeric: false, disablePadding: false, label: 'Data Inicial' },
    { id: 'final_date', align: 'center', numeric: false, disablePadding: false, label: 'Data Final' },
    { id: 'status', align: 'center', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'product', align: 'center', numeric: false, disablePadding: false, label: 'Produto' },
    { id: 'actions', align: 'center', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }
   
    return (
        <TableHead >
            <TableRow className={classes.tableHeadBorderless}  >
            {headCells.map((headCell) => (
                <TableCell
                key={headCell.id}
                align={headCell.align === 'center' ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                
                >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                    ) : null}
                </TableSortLabel>
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,//color da lista de requisições
            
        },
        '&:last-of-type > td': {
            borderBottom: '0 !important',
        },
    },
}))(TableRow);

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeadBorderless: {
        '&:last-of-type > th': {
            borderBottom: '0 !important',
        },
    }
}));

const TableCustomSort = props => {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('initial_date');

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertError, setAlertError] = useState('Por favor, verifique se todos os campos estão preenchidos corretamente.');
    const [rechargePage,setRechargePage]=useState(false)
    

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        
    };


    const handleDownload = async (event, id, name, type) => {
        setLoading(true);
        try {
            const response = await api.get(`/job/${type === 2 ? 'downloadCSV' : 'downloadXLSX'}/${id}`, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.${type === 2 ? 'csv' : 'xlsx'}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        } catch(e) {
            console.log(e);
        }
    }


    const handleDeleteRequest = async (event, row) => {
        setLoading(true);
        try {
            await api.delete(`/job/delete?ids=${row.id}`);
            setLoading(false);
            setSuccessOpen(true);
            props.handleChangePage(null, 0);
        } catch(e) {
            console.log(e);
            setLoading(false);
            setAlertError('Erro ao deletar a requisição.');
            setFailureOpen(true);
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
          return;
    
        setLoading(false);
    };


    return (
        <div className={classes.root}>
            <TableContainer components={{ Container: props => <Paper {...props} elevation={0} /> }}>
                <Table aria-label="panel table" className={'table-container'}>
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody >
                    {props.data.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            
                                <StyledTableRow
                                    tabIndex={-1}
                                    key={row.id}
                                >
                                    <TableCell id={labelId} scope="row" >
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{moment(row.initial_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="center">{moment(row.final_date).format('DD/MM/YYYY')}</TableCell>
                                    <TableCell align="center" >{row.status}</TableCell>
                                    

                                                                     
                                    <TableCell align="center">
                                        <Button onClick={e => {
                                                if(row.type === 1)
                                                    props.handleChart(e, row.id)
                                                else
                                                    handleDownload(e, row.id, row.name, row.type)
                                            }} disabled={row.status_id === 3 ? false : true} variant='contained' color='primary' >
                                            {row.product}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <span onClick={e => handleDeleteRequest(e, row)}>
                                            <DeleteIcon fill='currentColor' style={{color: '#00CCC0', cursor: 'pointer'}} />
                                        </span>
                                        <span onClick={e=> window.location.reload()}>
                                            <Replay fill='currentColor' style={{color: '#00CCC0', cursor: 'pointer'}} />
                                        </span>
                                    </TableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onChangePage={props.handleChangePage}
                rowsPerPageOptions={[5]}
            />
            

            

            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    Requisição deletada com sucesso!
                </Alert>
            </Snackbar>

            <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='error'>
                    {alertError}
                </Alert>
            </Snackbar>

            <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='info'>
                    <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                </Alert>
            </Snackbar>
            
        </div>
        
    );
};

export default TableCustomSort;