import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import './marker.styles.scss';

import MetereologicMarkerNoStatus from '../../../assets/map/icons/mr_1.svg';
import MetereologicMarkerActive from '../../../assets/map/icons/mr_2.svg';
import MetereologicMarkerInactive from '../../../assets/map/icons/mr_3.svg';

import WaterQualityMarkerNoStatus from '../../../assets/map/icons/wq_1.svg';
import WaterQualityMarkerActive from '../../../assets/map/icons/wq_2.svg';
import WaterQualityMarkerInactive from '../../../assets/map/icons/wq_3.svg';

import SubterraneanWaterMarkerNoStatus from '../../../assets/map/icons/sw_1.svg';
import SubterraneanWaterMarkerActive from '../../../assets/map/icons/sw_2.svg';
import SubterraneanWaterMarkerInactive from '../../../assets/map/icons/sw_3.svg';

import SedimentsMarkerNoStatus from '../../../assets/map/icons/sd_1.svg';
import SedimentsMarkerActive from '../../../assets/map/icons/sd_2.svg';
import SedimentsMarkerInactive from '../../../assets/map/icons/sd_3.svg';


const metereologicIconNoStatus = L.icon({
    iconUrl: MetereologicMarkerNoStatus,
    iconRetinaUrl: MetereologicMarkerNoStatus,
    iconSize: 32,
})

const metereologicIconActive = L.icon({
    iconUrl: MetereologicMarkerActive,
    iconRetinaUrl: MetereologicMarkerActive,
    iconSize: 32,
})

const metereologicIconInactive = L.icon({
    iconUrl: MetereologicMarkerInactive,
    iconRetinaUrl: MetereologicMarkerInactive,
    iconSize: 32,
})

const waterQualityIconNoStatus = L.icon({
    iconUrl: WaterQualityMarkerNoStatus,
    iconRetinaUrl: WaterQualityMarkerNoStatus,
    iconSize: 32,
})

const waterQualityIconActive = L.icon({
    iconUrl: WaterQualityMarkerActive,
    iconRetinaUrl: WaterQualityMarkerActive,
    iconSize: 32,
})

const waterQualityIconInactive = L.icon({
    iconUrl: WaterQualityMarkerInactive,
    iconRetinaUrl: WaterQualityMarkerInactive,
    iconSize: 32,
})

const subterraneanWaterIconNoStatus = L.icon({
    iconUrl: SubterraneanWaterMarkerNoStatus,
    iconRetinaUrl: SubterraneanWaterMarkerNoStatus,
    iconSize: 32,
})

const subterraneanWaterIconActive = L.icon({
    iconUrl: SubterraneanWaterMarkerActive,
    iconRetinaUrl: SubterraneanWaterMarkerActive,
    iconSize: 32,
})

const subterraneanWaterIconInactive = L.icon({
    iconUrl: SubterraneanWaterMarkerInactive,
    iconRetinaUrl: SubterraneanWaterMarkerInactive,
    iconSize: 32,
})

const sedimentsIconNoStatus = L.icon({
    iconUrl: SedimentsMarkerNoStatus,
    iconRetinaUrl: SedimentsMarkerNoStatus,
    iconSize: 32,
})

const sedimentsIconActive = L.icon({
    iconUrl: SedimentsMarkerActive,
    iconRetinaUrl: SedimentsMarkerActive,
    iconSize: 32,
})

const sedimentsIconInactive = L.icon({
    iconUrl: SedimentsMarkerInactive,
    iconRetinaUrl: SedimentsMarkerInactive,
    iconSize: 32,
})

const hidropedologicasIconNoStatus = L.icon({
    iconUrl: MetereologicMarkerNoStatus,
    iconRetinaUrl: MetereologicMarkerNoStatus,
    iconSize: 32,
})

const hidropedologicasIconActive = L.icon({
    iconUrl: MetereologicMarkerActive,
    iconRetinaUrl: MetereologicMarkerActive,
    iconSize: 32,
})

const hidropedologicasIconInactive = L.icon({
    iconUrl: MetereologicMarkerInactive,
    iconRetinaUrl: MetereologicMarkerInactive,
    iconSize: 32,
})
const agrometeorologicasIconNoStatus = L.icon({
    iconUrl: MetereologicMarkerNoStatus,
    iconRetinaUrl: MetereologicMarkerNoStatus,
    iconSize: 32,
})

const agrometeorologicasIconActive = L.icon({
    iconUrl: MetereologicMarkerActive,
    iconRetinaUrl: MetereologicMarkerActive,
    iconSize: 32,
})

const agrometeorologicasIconInactive = L.icon({
    iconUrl: MetereologicMarkerInactive,
    iconRetinaUrl: MetereologicMarkerInactive,
    iconSize: 32,
})
const hidrossedimentologicasIconNoStatus = L.icon({
    iconUrl: MetereologicMarkerNoStatus,
    iconRetinaUrl: MetereologicMarkerNoStatus,
    iconSize: 32,
})

const hidrossedimentologicasIconActive = L.icon({
    iconUrl: MetereologicMarkerActive,
    iconRetinaUrl: MetereologicMarkerActive,
    iconSize: 32,
})

const hidrossedimentologicasIconInactive = L.icon({
    iconUrl: MetereologicMarkerInactive,
    iconRetinaUrl: MetereologicMarkerInactive,
    iconSize: 32,
})

const mapIcons = {
    1: {
        1: metereologicIconNoStatus,
        2: metereologicIconActive,
        3: metereologicIconInactive,
    },
    2: {
        1: waterQualityIconNoStatus,
        2: waterQualityIconActive,
        3: waterQualityIconInactive,
    },
    4: {
        1: subterraneanWaterIconNoStatus,
        2: subterraneanWaterIconActive,
        3: subterraneanWaterIconInactive,
    },
    5: {
        1: sedimentsIconNoStatus,
        2: sedimentsIconActive,
        3: sedimentsIconInactive,
    },
    6:{
        1: hidrossedimentologicasIconNoStatus,
        2: hidrossedimentologicasIconActive,
        3: hidrossedimentologicasIconInactive,
    },
    7:{
        1: agrometeorologicasIconNoStatus,
        2: agrometeorologicasIconActive,
        3: agrometeorologicasIconInactive,
    },
    8:{
        1: hidropedologicasIconNoStatus,
        2: hidropedologicasIconActive,
        3: hidropedologicasIconInactive,
    }
}

const MarkerWaterQuality = (props) => {
    const markerIcon = mapIcons[props.marker.tipo][props.marker.status]
    

    if(!markerIcon) return <div></div>

    return (
        <Marker 
        position={[props.marker.lat, props.marker.log]} 
        icon={markerIcon}
        eventHandlers={{
            popupopen: (e) => {
                e.popup._closeButton.removeAttribute("href");
                e.popup._closeButton.style.cursor = "pointer";

            }
        }}
        >
            <Popup className='request-popup'>
                <div className={'marker-wrapper'}>
                    <ul>
                        <li><span>Tipo: </span> {props.metadata['Tipo']}</li>
                        <li><span>Código: </span> {props.marker.nome}</li>
                        <li><span>Responsável: </span> {props.metadata['Responsável']}</li>
                        <li><span>Localidade: </span> {props.metadata['Localidade']}</li>
                        <li><span>Município: </span> {props.metadata['Município']}</li>
                        <li><span>Bacia: </span> {props.metadata['Bacia']}</li>
                        <li><span>Corpo Hídrico: </span> {props.metadata['Corpo Hídrico']}</li>
                        <li><span>Tipo Sistema: </span> {props.metadata['Tipo Sistema']}</li>
                        <li><span>Início da operação: </span> {props.metadata['Inicio da Operação']}</li>
                        <li><span>Situação: </span> {props.metadata['Situação']}</li>
                    </ul>
                </div>
            </Popup>
        </Marker>)
}

const MarkerDefault = (props) => {
    const markerIcon = mapIcons[props.marker.tipo][props.marker.status]

    
    if(!markerIcon) return <div></div>

    return (
        <Marker 
        position={[props.marker.lat, props.marker.log]} 
        icon={markerIcon}
        eventHandlers={{
            popupopen: (e) => {
                e.popup._closeButton.removeAttribute("href");
                e.popup._closeButton.style.cursor = "pointer";
            }
        }}
        >
            <Popup className='request-popup'>
                <div className={'marker-wrapper'}>
                    <ul>
                        <li><span>Tipo: </span> {props.metadata['Tipo']}</li>
                        <li><span>Código: </span> {props.marker.nome}</li>
                        
                        { props.marker.tipo === 1 ? (<li><span>Responsável: </span> {props.metadata['Responsável']}</li>) : null }
                        <li><span>Início da operação: </span> {props.metadata['Inicio da Operação']}</li>
                        <li><span>Situação: </span> {props.metadata['Situação']}</li>
                    </ul>
                </div>
            </Popup>
        </Marker>)
}

export { MarkerWaterQuality, MarkerDefault };