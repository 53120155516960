import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

import {ReactComponent as EditIcon} from '../../../../assets/admin/edit-box-line.svg';
import {ReactComponent as PermissionsIcon} from '../../../../assets/admin/profile-line.svg';

import api from '../../../../api';

const headCells = [
    { id: 'status', align: 'center', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Nome completo' },
    { id: 'email', align: 'left', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'phone', align: 'left', numeric: false, disablePadding: false, label: 'Telefone' },
    { id: 'idiom', align: 'left', numeric: false, disablePadding: false, label: 'Idioma' },
    { id: 'actions', align: 'left', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow className={classes.tableHeadBorderless}>
            {headCells.map((headCell) => (
                <TableCell
                key={headCell.id}
                align={headCell.align === 'center' ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                    ) : null}
                </TableSortLabel>
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-of-type > td': {
            borderBottom: '0 !important',
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeadBorderless: {
        '&:last-of-type > th': {
            borderBottom: '0 !important',
        },
    },
    statusOpen: {
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            '-moz-border-radius': '7.5px',
            '-webkit-border-radius': '7.5px',
            borderRadius: '7.5px',
            backgroundColor: '#4CAF50',
            position: 'absolute',
            left: '50%',
            marginLeft: '-16px',
            bottom: '24px',
            cursor: 'pointer',
        },
    },
    statusClosed: {
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            '-moz-border-radius': '7.5px',
            '-webkit-border-radius': '7.5px',
            borderRadius: '7.5px',
            backgroundColor: '#F44336',
            position: 'absolute',
            left: '50%',
            marginLeft: '-16px',
            bottom: '24px',
            cursor: 'pointer',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '2px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 3),
        maxWidth: '500px',
        '& > :first-child': {
            fontWeight: '500',
        },
        '& > :first-child + div': {
            margin: '0.8rem 0',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center',
        },
        '& > :first-child + div + div': {
            textAlign: 'justify',
        },
        '&:focus': {
            outline: 'none !important',
        },
        '& > img': {
            paddingTop: '1rem',
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const UserListTable = props => {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('initial_date');

    const [modalOpen, setModalOpen] = useState(false);
    const [editId, setEditId] = useState(0);
    const [editName, setEditName] = useState('');
    const [editDocument, setEditDocument] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editPhone, setEditPhone] = useState('');

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertError, setAlertError] = useState('Por favor, verifique se todos os campos estão preenchidos corretamente.');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
          return;
        
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    };

    const handleSwitchUser = async (e, id) => {
        if(props.userMaxLevel === 10) {
            setLoading(true);
            try {
                await api.put(`usuario/atualiza/status/${id}`);
                document.getElementById(`status-${id}`).classList.toggle(classes.statusClosed);
                setLoading(false);
            } catch(e) {
                console.log(e);
            }
        } else {
            setAlertError('Necessário permissão de administrador para alterar o status de um usuário.');
            setLoading(false);
            setFailureOpen(true);
        }
    }

    const handleOpenModal = (event, user) => {
        setEditId(user.id);
        setEditName(user.name);
        setEditDocument(user.document);
        setEditEmail(user.email);
        setEditPhone(user.phone);
        setModalOpen(true);
    }

    const handleEditUser = async (event) => {
        try {
            const data = {
                telefone: editPhone
            }
            await api.put(`usuario/atualiza/metadados/${editId}`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            props.handleChangePage(null, 0);
            setModalOpen(false);
            setSuccessOpen(true);
        } catch(e) {
            setAlertError('Erro ao atualizar as informações do usuário.');
            setFailureOpen(true);
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby='modal-edit-user'
                aria-describedby='modal-edit-user-description'
                className={classes.modal}
                open={modalOpen}
                onClose={e => setModalOpen(false)}
                closeAfterTransition={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <Box className={classes.paperModal} display='flex' flexDirection='column' minWidth={'500px'}>
                        <div>Editar Usuário</div>
                        <TextField
                            name='editName'
                            value={editName}
                            label='Nome completo'
                            placeholder='Nome'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setEditName(e.target.value)}
                            disabled
                        />
                        <Box display='flex' flexDirection='row' marginBottom='1rem'>
                            <TextField
                                name='editEmail'
                                value={editEmail}
                                label='Email'
                                placeholder='exemplo@exemplo.com'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin='normal'
                                variant='outlined'
                                onChange={e => setEditEmail(e.target.value)}
                                style={{ marginRight: '0.5rem', marginBottom: 0 }}
                                disabled
                            />

                            <TextField
                                name='editPhone'
                                value={editPhone}
                                label='Telefone'
                                placeholder='(00) 00000-0000'
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin='normal'
                                variant='outlined'
                                onChange={e => setEditPhone(e.target.value)}
                                style={{ marginLeft: '0.5rem', marginBottom: 0 }}
                            />
                        </Box>

                        <Box display='flex' justifyContent='flex-end' marginTop='0.5rem'>
                            <Button onClick={handleEditUser} variant='contained' color='primary'>
                                CONFIRMAR
                            </Button>
                        </Box>

                        <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity='error'>
                                {alertError}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Fade>
            </Modal>

            <div className={classes.root}>
                <TableContainer components={{ Container: props => <Paper {...props} elevation={0}/> }}>
                    <Table aria-label='panel table' className={'table-container'}>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                        {props.data.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                    <StyledTableRow
                                        tabIndex={-1}
                                        key={row.id}
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        <TableCell style={{position: 'relative'}}><span id={`status-${row.id}`} className={`${classes.statusOpen} ${row.status ? '' : classes.statusClosed}`} onClick={e => handleSwitchUser(e, row.id)}></span></TableCell>
                                        <TableCell id={labelId} scope="row" >
                                            {row.name}
                                        </TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.phone}</TableCell>
                                        <TableCell>{row.idiom}</TableCell>
                                        <TableCell>
                                            <span onClick={e => handleOpenModal(e, row)}>
                                                <EditIcon fill='currentColor' style={{color: '#00CCC0', cursor: 'pointer'}} />
                                            </span>
                                            <span onClick={e => {
                                                props.setEditPermissions({
                                                    id: row.id,
                                                    name: row.name
                                                })
                                                props.handleChangeTab(e, 2);
                                            }}>
                                                <PermissionsIcon fill='currentColor' style={{color: '#00CCC0', cursor: 'pointer'}} />
                                            </span>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={props.count}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={props.handleChangePage}
                    rowsPerPageOptions={[5]}
                />
            </div>

            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    Usuário atualizado com sucesso!
                </Alert>
            </Snackbar>

            <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='info'>
                    <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                </Alert>
            </Snackbar>
        </div>
    );
};

export default UserListTable;