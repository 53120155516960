import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import UploadListTable from './upload-list-table/upload-list-table.component';
import Loading from '../../loading/loading.component';

import api from '../../../api';

const UploadList = (props) => {
    const [loadingData, setLoadingData] = useState(true);
    const [fileSearch, setFileSearch] = useState('');

    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        (async () => {
            const uploadList = await api.get('/importar/lista?pageNo=0&pageSize=5');
            setCount(uploadList.data.totalElements);
            updateTableData(uploadList.data.content);
            setLoadingData(false);
        })();
    }, []);

    const updateTableData = (data) => {
        const formattedData = data.map(item => ({
            id: item.id,
            status: item.status,
            name: item.nomeArquivo,
            stored: item.arquivoArmazenado,
            persisted: item.persistido,
            contractId: item.contratoID,
            contractName: item.contratoNome,
            institution: item.instituicao,
            institutionArea: item.areaInstituicao,
            applicationArea: item.areaAplicacao,
            user: item.usuario,
        }));
        setTableData(formattedData);
    }

    const handleChangePage = async (event, newPage) => {
        try {
            const request = await api.get(`/importar/lista?pageNo=${newPage}&pageSize=5`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(newPage);
        } catch(e) {
            console.log(e);
        }
    }

    const handleTableSearch = async (event) => {
        event.preventDefault();
        try {
            const request = await api.get(`/importar/lista?pageNo=0&pageSize=5&search=${fileSearch}`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(0);
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <div>
            <form onSubmit={handleTableSearch}>
                <Grid container spacing={2} style={{marginBottom: '0.313rem'}}>
                    <Grid item xs={12} sm={5}>
                        <TextField 
                            id='file'
                            label='Buscar arquivo'
                            placeholder='Nome'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            value={fileSearch}
                            onChange={e => setFileSearch(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <Box display='flex' height='100%' justifyContent='space-between' alignItems='center'>
                            <Button type='submit' variant='contained' color='primary' style={{maxHeight: '2.375rem'}} >
                                BUSCAR
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>

            {
                loadingData
                ?
                (<Loading />)
                :
                <UploadListTable 
                    page={page}
                    rowsPerPage={5}
                    count={count}
                    handleChangePage={handleChangePage}
                    data={tableData}
                    userMaxLevel={props.userMaxLevel}
                />
            }
        </div>
    )
}

export default UploadList;