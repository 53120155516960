import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckboxTree from 'react-checkbox-tree';

import api from '../../../api';

import SelectInput from '../../select-input/select-input.component';
import CheckboxField from '../../checkbox-field/checkbox-field.component';

import './permissions.styles.scss';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Permissions = (props) => {
    const [institutionsList, setInstitutionsList] = useState([{
        id: 0,
        name: ''
    }]);
    const [profilesList, setProfilesList] = useState([{
        id: 0,
        name: ''
    }]);
    const [hasBond, setHasBond] = useState(false);
    const [bondId, setBondId] = useState(0);
    const [institution, setInstitution] = useState('');
    const [profile, setProfile] = useState('');
    const [stationName, setStationName] = useState('');
    const [stationType, setStationType] = useState([]);

    const [nodes, setNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodesChecked, setNodesChecked] = useState([]);

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [alertError, setAlertError] = useState('Por favor, verifique se todos os campos estão preenchidos corretamente.');
    const [alertSuccess, setAlertSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get('/instituicao/usuario');
                const newValues = data.map(item => {
                        return {
                            id: item.id,
                            name: item.nome
                        }
                    }
                );
                setInstitutionsList(newValues);
                if(props.editPermissions.hasOwnProperty('institution'))
                    setInstitution(props.editPermissions.institution);
                else
                    setInstitution(newValues[0].id);

                const getProfiles = await api.get(`/usuario/perfis/${newValues[0].id}`);
                setProfilesList(getProfiles.data.map(item => {
                    return {
                        id: item.levelaccess,
                        name: item.name
                    }
                }));

                const getNodes = await api.get(`/usuario/permissoes?idInstituicao=${
                    props.editPermissions.hasOwnProperty('institution') ? props.editPermissions.institution : newValues[0].id
                }`);
                setNodes(getNodes.data);
                if(getNodes.data[0].hasOwnProperty('children')) {
                    setExpanded([
                        getNodes.data[0].value,
                        getNodes.data[0].children[0].value,
                    ]);
                }

                const getUserNodes = await api.get(`/usuario/vinculo/${props.editPermissions.id}/${newValues[0].id}`);
                if(getUserNodes.data.hasOwnProperty('id')){
                    setHasBond(true);
                    setBondId(getUserNodes.data.id);
                    setProfile(getUserNodes.data.perfil.levelaccess);
                } else {
                    setProfile(getProfiles.data[0].levelaccess);
                }
                setNodesChecked(getUserNodes.data.permissoes);
            } catch(e) {
                console.log(e);
            }
        })();
    }, []);

    const handleInstitutionChange = async (event) => {
        setInstitution(event.target.value);
        try {
            const { data } = await api.get(`/usuario/perfis/${event.target.value}`);
            setProfilesList(data.map(item => {
                return {
                    id: item.levelaccess,
                    name: item.name
                }
            }));

            const getNodes = await api.get(`/usuario/permissoes?idInstituicao=${event.target.value}`);
            setNodes(getNodes.data);
            if(getNodes.data[0].hasOwnProperty('children')) {
                setExpanded([
                    getNodes.data[0].value,
                    getNodes.data[0].children[0].value,
                ]);
            }

            const getUserNodes = await api.get(`/usuario/vinculo/${props.editPermissions.id}/${event.target.value}`);
            if(getUserNodes.data.hasOwnProperty('id')) {
                setHasBond(true);
                setBondId(getUserNodes.data.id);
                setProfile(getUserNodes.data.perfil.levelaccess);
            } else {
                setHasBond(false);
                setBondId(0);
                setProfile(data[0].levelaccess);
            }
            setNodesChecked(getUserNodes.data.permissoes);
        } catch (e) {
            console.log(e);
        }
    }

    const handleProfileChange = async (event) => {
        setProfile(event.target.value);
    }

    const handleSearchStation = async (event) => {
        try {
            const stationTypes = []
            const sensorsIds = []
            stationType.map(item => {
                if (item.includes('/')) {
                    const split = item.split('/');
                    if(!stationTypes.includes(split[0]))
                        stationTypes.push(split[0]);
                    sensorsIds.push(split[1]);
                } else {
                    stationTypes.push(item);
                }
                return null
            });
            const { data } = await api.get(`/usuario/permissoes?idInstituicao=${institution}&tipoEstacao=${stationTypes}&search=${stationName}&idSensor=${sensorsIds}`);
            const resp = await JSON.stringify(data);
            setNodes(JSON.parse(resp));
        } catch(e) {
            console.log(e);
        }
    }

    const handleClearSearchStation = async (event) => {
        try {
            setStationName('');
            setStationType([]);
            const { data } = await api.get(`/usuario/permissoes?idInstituicao=${institution}`);
            const nodes = await JSON.stringify(data);
            setNodes(JSON.parse(nodes));
        } catch(e) {
            console.log(e);
        }
    }

    const handleUpdateBond = async (event) => {
        setLoading(true);
        try {
            if(hasBond) {
                const data = {
                    idPerfil: profile,
                    permissoes: nodesChecked
                }
                await api.put(`/usuario/atualiza/vinculo/${bondId}`, JSON.stringify(data), {
                    headers: {'Content-Type': 'application/json'}
                });
            } else {
                const data = {
                    aplicacao: 'sima',
                    idAreaAplicacao: '01',
                    idAreaIntitution: '01',
                    idInstiuicao: `${institution}`,
                    idPerfil: profile,
                    permissoes: nodesChecked
                }
                await api.post(`/usuario/novo/vinculo/${props.editPermissions.id}`, JSON.stringify(data), {
                    headers: {'Content-Type': 'application/json'}
                });
            }
            setLoading(false);
            setAlertSuccess('Permissões atualizadas com sucesso!');
            setSuccessOpen(true);
        } catch(e) {
            setAlertError(e.response.data);
            setLoading(false);
            setFailureOpen(true);
        }
    }

    const handleDeleteBond = async (event) => {
        setLoading(true);
        if(hasBond) {
            try {
                await api.delete(`/usuario/vinculo/${bondId}/${props.editPermissions.id}`);
                setLoading(false);
                setAlertSuccess('Vínculo excluído com sucesso!');
                setSuccessOpen(true);
                setHasBond(false);
                setBondId(0);
                handleInstitutionChange({target: {value: institution}});
            } catch(e) {
                setLoading(false);
                setAlertError(e.response.data);
                setFailureOpen(true);
            }
        } else {
            setLoading(false);
            setAlertError('O usuário precisa ter um vínculo criado previamente!');
            setFailureOpen(true);
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
    
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    }

    return (<div>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField 
                            id='user'
                            label='Usuário'
                            value={props.editPermissions.name}
                            placeholder='Nome'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            disabled
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <SelectInput
                            label='institution'
                            labelTitle='Instituição'
                            values={institutionsList}
                            value={institution}
                            handleChange={handleInstitutionChange}
                            disabled={props.editPermissions.institution ? true : false}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <SelectInput
                            label='profile'
                            labelTitle='Perfil'
                            values={profilesList}
                            value={profile}
                            handleChange={handleProfileChange}
                        />
                    </Grid>
                </Grid>

                <Divider style={{margin: '0.5rem 0'}} />

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextField 
                            id='station'
                            label='Estação'
                            placeholder='Procurar por uma estação'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setStationName(e.target.value)}
                            value={stationName}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <CheckboxField stationType={stationType} setStationType={setStationType} />
                    </Grid>

                    <Grid item xs={4} style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' flexGrow={1} padding='1.5rem' margin='-0.5rem' marginLeft='-1rem'>
                                <Button onClick={handleClearSearchStation} variant='contained' color='secondary' style={{margin: '0.5rem', marginLeft: '-0.5rem', maxHeight: '2.375rem'}} fullWidth>
                                    LIMPAR
                                </Button>
                                <Button onClick={handleSearchStation} type='submit' variant='contained' color='primary' style={{margin: '0.5rem', maxHeight: '2.375rem'}} fullWidth>
                                    BUSCAR
                                </Button>
                        </Box>
                    </Grid>
                </Grid>

                <div className='permissions-checkbox-field-wrapper'>
                    <Typography component='span'>Permissões do usuário</Typography>
                    <div className='permissions-checkbox-tree-wrapper'>
                        <CheckboxTree
                            checked={nodesChecked}
                            expanded={expanded}
                            iconsClass="fa5"
                            nodes={nodes}
                            onCheck={checked => setNodesChecked(checked)}
                            onExpand={expanded => setExpanded(expanded)}
                            icons={{
                                check: <span className="rct-icon rct-icon-check" />,
                                uncheck: <span className="rct-icon rct-icon-uncheck" />,
                                halfCheck: <span className="rct-icon rct-icon-half-check" />,
                                expandClose: <span className="rct-icon rct-icon-expand-close" />,
                                expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                                expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                parentClose: <span className="rct-icon rct-icon-parent-close" />,
                                parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                                leaf: <span className="rct-icon rct-icon-leaf" />,
                            }}
                            showExpandAll
                        />
                    </div>
                </div>

                <Box display='flex' justifyContent='flex-end'>
                    <Button onClick={handleDeleteBond} variant='contained' color='secondary' style={{margin: '0.5rem', marginLeft: '-0.5rem'}} >
                        EXCLUIR VÍNCULO
                    </Button>
                    <Button onClick={handleUpdateBond} variant='contained' color='primary' style={{margin: '0.5rem'}} >
                        ATUALIZAR
                    </Button>
                </Box>

                <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleAlertClose} severity='success'>
                        {alertSuccess}
                    </Alert>
                </Snackbar>

                <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleAlertClose} severity='error'>
                        {alertError}
                    </Alert>
                </Snackbar>

                <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleAlertClose} severity='info'>
                        <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                    </Alert>
                </Snackbar>

            </div>);
}

export default Permissions;