import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment';

import './new-device.styles.scss'

import api from '../../../api'
import MultiselectInput from '../../multiselect-input/multiselect-input.component'
import { Grid } from '@material-ui/core'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const NewDevice = () => {
  const [name, setName] = useState('')
  const [defaulValueDescription, setDefaultValueDescription] = useState("A descrição será preenchida quando os campos acima forem preenchidos")
  const [description, setDescription] = useState(JSON.stringify(defaulValueDescription, undefined, 4))
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [deviceId, setDeviceId] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [parameters, setParameters] = useState([])
  const [contracts, setContracts] = useState([])
  const [contractsOptions, setContractsOptions] = useState([])
  const [parametersOptions, setParametersOptions] = useState([])
  const [disabled, setDisabled] = useState(true);

  const [institutionList, setInstitutionList] = useState([])
  const [institutionAreaList, setInstitutionAreaList] = useState([])
  const [applicationAreaList, setApplicationAreaList] = useState([])
  const [contractList, setContractList] = useState([])
  const [parameterList, setParameterList] = useState([])

  const [institution, setInstitution] = useState({})
  const [institutionArea, setInstitutionArea] = useState({})
  const [applicationArea, setApplicationArea] = useState(0)
  const [contract, setContract] = useState(0)

  const [deviceList, setDeviceList] = useState([])

  const [isJsonValid, setIsJsonValid] = useState(true);
  const [successOpen, setSuccessOpen] = useState(false)
  const [failureOpen, setFailureOpen] = useState(false)
  const [alertError, setAlertError] = useState(
    'Por favor, verifique se todos os campos estão preenchidos corretamente.',
  )
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    ;(async () => {
      try {
        const getInstitutionList = await api.get('/instituicao/usuario')
        setInstitutionList(getInstitutionList.data)

        const getContractList = await api.get('/importar/contrato/lista')
        // console.log(getContractList.data)
        setContractsOptions(
          getContractList.data.map((item, index) => {
              return {
                  'id': item.id,
                  'value': item.id,
                  'label': item.descricao
              }
        }));

        const getAllSensors = await api.get("/sensor/todos?notPageable=true");
        // const parameters = await JSON.parse(request.data.parametros);
        // console.log(getAllSensors.data.content)
        setParametersOptions(
          getAllSensors.data.content.map((item, index) => {
              return {
                  'id': item.id,
                  'value': item.nomeFantasia.valor,
                  'label': item.nomeFantasia.valor
              }
        }));
        
      // // console.log(parametersOptions)
        // // console.log(contractsOptions)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') return

    setSuccessOpen(false)
    setFailureOpen(false)
    setLoading(false)
  }

  const handleInstitutionChange = async (event, newValue) => {
    setInstitutionArea({})
    setApplicationArea({})
    if (!newValue) {
      setInstitution({})
      setInstitutionAreaList([])
      setApplicationAreaList([])
      return
    }

    setInstitution(newValue)
    try {
      const { data } = await api.get(`/instituicao/areas/${newValue.id}`)
      setInstitutionAreaList(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleInstitutionAreaChange = async (event, newValue) => {
    setApplicationArea({})
    if (!newValue) {
      setInstitutionArea({})
      setApplicationAreaList([])
      return
    }
    setInstitutionArea(newValue)

    try {
      const { data } = await api.get(
        `/instituicao/areas/aplicacao/${institution.id}/${newValue.areaid.id}`,
      )
      setApplicationAreaList(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleApplicationAreaChange = (event, newValue) => {
    // // console.log(newValue.areaDeAplicacaoid)
    const defaultDescription = {
      "Tipo": newValue?.nome || "",
      "Situação": "Ativo",
      "Id Estação": deviceId || "",
      "Responsável": institutionArea?.nome || "",
      "Inicio da Operação": initialDate ? moment(initialDate).format('YYYY-MM-DD HH:mm:ss') : "",
      // "Fim da Operação": finalDate ? moment(finalDate).format('YYYY-MM-DD H:mm:ss') : "",
      ...(finalDate && {"Fim da Operação": moment(finalDate).format('YYYY-MM-DD HH:mm:ss')})
    }
    const descriptionQualityWater = {
      "Tipo": newValue?.nome || "",
      "Bacia": "",
      "Localidade": "",
      "Município": "",
      "Situação": "Ativo",
      "Id Estação": deviceId || "",
      "Responsável": institutionArea?.nome || "",
      "Tipo Sistema": "",
      "Corpo Hídrico": "Reservatório de Itaipu",
      "Início da Operação": initialDate ? moment(initialDate).format('YYYY-MM-DD H:mm:ss') : "",
      ...(finalDate && {"Fim da Operação": moment(finalDate).format('YYYY-MM-DD H:mm:ss')})
    }
    if (!newValue) return setApplicationArea({})
    setApplicationArea(newValue)    

    if(newValue.areaDeAplicacaoid.id === '05'){
      setDisabled(false)
      setDescription(JSON.stringify(descriptionQualityWater, undefined, 4))
    } else {
      setDescription(JSON.stringify(defaultDescription, undefined, 4))
      setDisabled(true)
    }
    // setDefaultValueDescription(JSON.stringify(defaultDescription, undefined, 4))
   /*  if(newValue.areaDeAplicacaoid.id === 5){
      getParameterList(1);
    } else if(newValue.areaDeAplicacaoid.id === )
 */
    
  }

  const handleDescriptionChange= (event) => {
    const value = event.target.value;
    setDescription(value);
    try {
      JSON.parse(value);
      setIsJsonValid(true);
    } catch (error) {
      console.log(error.message)
      setIsJsonValid(false);
    }
  }

 /*  const getParameterList = async (id_filter) => {
    const request = api.get(`/instituicao/filtro/${id_filter}`);
    const parameters = JSON.parse(request.data.parametros);
    // console.log(parameters)
    setParametersOptions(
      parameters.parametros.map((item, index) => {
          return {
              'id': index,
              'value': item,
              'label': item
          }
    }));
  }
 */
  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true)

    // console.log()

    const data = {
      ativo: true,
      dataDeCriacao: initialDate ? moment(initialDate).format('YYYY-MM-DD HH:mm:ss'):null,
      descricao: JSON.parse(description),
      dispid: {
        application_area_id: applicationArea.areaDeAplicacaoid.id,
        area_institution_id: institutionArea.areaid.id,
        ecosystem_id: 'sima',
        id: deviceId,
        institution_id: institution.id,
      },
      fimDeOperacao: finalDate ? moment(finalDate).format('YYYY-MM-DD HH:mm:ss'):null,
      latitude: latitude,
      listaIdContratos: contracts.map((item) => item.id),
      listaIdSensores: parameters.map((item) => item.id),
      longitude: longitude,
      nome: name,
    }
    // console.log(data)
    try {
      await api.post('/dispositivo/novo', JSON.stringify([data]), {
        headers: { 'Content-Type': 'application/json' },
      })
      await api.get('/usuario/atualiza/permissoes', {
        headers: { 'Content-Type': 'application/json' },
      })
      setLoading(false);
      setSuccessOpen(true);
      window.location.reload();
    } catch (e) {
      console.log(e)
      setLoading(false)
      if (e.hasOwnProperty('response')) setAlertError(e.response.data ?? e.message)
      else
        setAlertError(
          'Verifique se todos os campos estão preenchidos corretamente!',
        )
      setFailureOpen(true)
    }
  }

  /*   const handleDataType = async (e) => {
    setDataType(e.target.value);//fitoplancton ou zooplancton
    
    
    try {
        if(e.target.value === 'Fitoplâncton') {
            const request = await api.get('/instituicao/filtro/4');
            const parameters = await JSON.parse(request.data.parametros);
            setBiologicalMultiSelectValues(parameters);
            
            
        } else {
            const request = await api.get('/instituicao/filtro/3');
            const parameters = await JSON.parse(request.data.parametros);
            setBiologicalMultiSelectValues(parameters);
        }
    } catch(e) {
        // console.log(e.response.data);
    }

  } 
  
  const descriptionQualityWater = {
  "Tipo": "Qualidade de Água",
  "Bacia": "",
  "Localidade": "",
  "Município": "",
  "Situação": "Ativo",
  "Id Estação": deviceId || "",
  "Responsável": institutionArea?.nome || "",
  "Tipo Sistema": "",
  "Corpo Hídrico": "Reservatório de Itaipu",
  "Início da Operação": initialDate ? moment(initialDate).format('YYYY-MM-DD H:mm:ss') : "",
  ...(finalDate && {"Fim da Operação": moment(finalDate).format('YYYY-MM-DD H:mm:ss')})
} */

  return (
    <div style={{ width: '100%'}}>
      <form onSubmit={handleSave}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={2}>
            <TextField
                id="device_id"
                label="id da estação"
                placeholder="0X26F892"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                margin="normal"
                variant="outlined"
                onChange={(e) => setDeviceId(e.target.value)}
                inputProps={{ maxLength: 8 }}
              />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="name"
              label="Nome da estação"
              required
              placeholder="Exemplo"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="date-initial"
              label="Data inicial"
              required
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="date-final"
              label="Data final"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="latitude"
              label="Latitude"
              placeholder="-00.0000000"
              required
              InputLabelProps={{
                shrink: true,
              }}              
              margin="normal"
              variant="outlined"
              onChange={(e) => setLatitude(e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="longitude"
              label="Longitude"
              placeholder="-00.000000"
              required
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              onChange={(e) => setLongitude(e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <Autocomplete
              disablePortal
              id="institution"
              options={institutionList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                  placeholder="Selecione uma Instituição"
                  variant="outlined"
                  label="Instituição"
                />
              )}
              getOptionLabel={(option) => option.nome || ''}
              onChange={handleInstitutionChange}
              value={institution}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Autocomplete
              disablePortal
              id="areaInstitution"
              options={institutionAreaList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                  placeholder="Selecione uma Área da Instituição"
                  variant="outlined"
                  label="Área da Instituição"
                />
              )}
              getOptionLabel={(option) => option.nome || ''}
              onChange={handleInstitutionAreaChange}
              value={institutionArea}
            />
          </Grid>
          <Grid item xs={6} sm={4} >
            <Autocomplete
              disablePortal
              id="areaApplication"
              options={applicationAreaList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                  placeholder="Selecione uma Área da Aplicação"
                  variant="outlined"
                  label="Área da Aplicação"
                />
              )}
              getOptionLabel={(option) => option.nome || ''}
              onChange={handleApplicationAreaChange}
              value={applicationArea}
            />
          </Grid>

        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={4}>
            <MultiselectInput
              label="Sensores"
              placeholder="Selecionar sensores"
              options={parametersOptions}
              onChange={(selected) => setParameters(selected)}
              allowSelectAll={false}
              value={parameters}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <MultiselectInput
              label="Contratos"
              placeholder="Selecionar contratos"
              options={contractsOptions}
              onChange={(selected) => setContracts(selected)}
              allowSelectAll={false}
              value={contracts}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="descricao"
              label="Descrição"
              InputLabelProps={{
                shrink: true,
              }}
              value={description}
              fullWidth 
              disabled={disabled}
              multiline
              minRows={4}
              margin="normal"
              variant="outlined"
              error={!isJsonValid}
              helperText={!isJsonValid ? 'Formato inválido, verifique se o valor esta entre aspas duplas ou falta alguma virgula' : ''}
              onChange={(e) => handleDescriptionChange(e)}
            />
          </Grid>
        </Grid>
        <div className='button-wrapper multiples'>
            <Button variant='contained' color='secondary' type='reset'>
                LIMPAR
            </Button>
            <Button variant='contained' color='primary' type='submit' >
                SALVAR
            </Button>
        </div>
      </form>
      <Snackbar
        open={successOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleAlertClose} severity="success">
          A estação foi salva com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={failureOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertError}
        </Alert>
      </Snackbar>

      <Snackbar
        open={loading}
        autoHideDuration={null}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        style={{ zIndex: '9999' }}
      >
        <Alert onClose={handleAlertClose} severity="info">
          <CircularProgress size={12} color="secondary" /> &nbsp; Carregando...
        </Alert>
      </Snackbar>
    </div> 
  );
}

export default NewDevice
