import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Header from '../../components/header/header.component';
import DrawerNav from '../../components/drawer-nav/drawer-nav.component';
import MainPanel from '../../components/main-panel/main-panel.component';
import Requests from '../../components/requests/requests.component';
import FiltersPanel from '../../components/filters-panel/filters-panel.component';
import Admin from '../../components/admin/admin.component';
import UploadManager from '../../components/upload-manager/upload-manager.component';
import DeviceManager from '../../components/device-manager/device-manager.component';
import About from '../../components/about/about.component';
import Map from '../../components/map/map.component';
import './homepage.styles.scss';

import { MarkerProvider } from '../../context/MainContext';
import Cards from '../ListCards/Cards';


const AdminPanel = () => (
    <MainPanel title='Administrativo' defaultExpanded={true}>
        <Admin />
    </MainPanel>
);

const FilterPanel = () => (
    <MainPanel title='Filtros' defaultExpanded={true}>
        <Helmet>
            <title>SIMA - Filtros</title>
            <meta name="description" content="Filter Page" />
        </Helmet>
        <FiltersPanel />
    </MainPanel>
);

const RequestsPanel = () => (
    <MainPanel title='Lista de Requisições' defaultExpanded={true}>
        <Helmet>
            <title>SIMA - Requisições</title>
            <meta name="description" content="Request Page" />
        </Helmet>
        <Requests />
    </MainPanel>
);

const UploadManagerPanel = () => (
    <MainPanel title='Upload de Arquivos .CSV' defaultExpanded={true}>
        <UploadManager />
    </MainPanel>
);

const DeviceManagerPanel = () => (
    <MainPanel title='Gestão de estações' defaultExpanded={true}>
        <DeviceManager />
    </MainPanel>
);

const RenderMainPanel = (props) => {

    
    return (
        <Switch>
            { props.panel === 'filter' &&<Route exact path='/Meteorologico' component={FilterPanel} /> }
            { props.panel === 'filter' &&  <Route exact path='/Sedimentos' component={FilterPanel} /> }
            { props.panel === 'filter' && <Route exact path='/AguasSubterraneas' component={FilterPanel} />}
            { props.panel === 'filter' && <Route exact path='/QualidadeAgua' component={FilterPanel} />}

           
            <Route exact path='/admin' component={AdminPanel} />
            <Route exact path='/upload' component={UploadManagerPanel} />
            <Route exact path='/device' component={DeviceManagerPanel} />
            <Route exact path='/requests' component={RequestsPanel} />
            <Route exact path='/about' component={About} />
            <Route exact path='/' component={Cards}/>
           
            
        </Switch>
    );// 
}

const HomePage = (props) => {

    const drawerWidth = 420;

    const useStyles = makeStyles((theme) => ({
        content: {
            width: '100vw',
            height: '100%',
            overflow: 'hidden',
            // backgroundImage: `url(${Image})`,
            backgroundSize: 'cover'
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        }
    }));

    const classes = useStyles();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [renderedMainPanel, setRenderedMainPanel] = useState(null);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    return (
        <MarkerProvider>
            <Helmet>
                <title>SIMA - Home</title>
                <meta name="description" content="Home Page" />
            </Helmet>
            <div className='main-wrapper'>
                <CssBaseline />
                <Header 
                    open={openDrawer}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                />
                <DrawerNav
                    open={openDrawer}
                    setOpenDrawer={setOpenDrawer}
                    drawerWidth={drawerWidth} 
                    handleRenderedMainPanel={setRenderedMainPanel}
                />

                <main className={classes.content}>
                    <div className={classes.drawerHeader} />
                    <RenderMainPanel panel={renderedMainPanel} />
                    <Map />
                </main>
            </div>
        </MarkerProvider>
    );
}

export default HomePage;