import React, { createContext, useState, useEffect } from 'react';

import api from '../api';
import history from '../history';

const Context = createContext();

function AuthProvider({ children }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({

        id: 0,
        nome: '',
        documento: '',
        email: '',
        telefone: '',
    });

    useEffect(() => {
        const token = localStorage.getItem('token');

        if(token) {
            api.defaults.headers.Authorization = token;
            setAuthenticated(true);
        }

        setLoading(false);
    }, []);

    async function handleLogin(dataObject) {
        try {
            const { data } = await api.post('/login', dataObject);
            localStorage.setItem('token', data.split(':')[1]);
            api.defaults.headers.Authorization = data.split(':')[1];
            await setUserMetadata();
            setAuthenticated(true);
            history.push('/');
        } catch(e) {
            return false;
        }
    }

    function setUserMetadata() {
        return new Promise(async (resolve, reject) => {
            try {
                const { data } = await api.get('/usuario/metadados');
                const maxLevelAccess = data.vinculos.map(item => item.perfil.levelaccess).reduce(function(a, b) {
                    return Math.max(a, b);
                });
                data.maxLevelAccess = maxLevelAccess;
                setUser(data);
                return resolve();
            } catch(e) {
                return reject(e);
            }
        });
    }

    function handleLogout() {
        setAuthenticated(false);
        localStorage.removeItem('token');
        api.defaults.headers.Authorization = undefined;
        history.push('/login');
    }

    return (
        <Context.Provider value={{ authenticated, handleLogin, handleLogout, loading, user, setUserMetadata }}>
            {children}
        </Context.Provider>
    );
}

export { Context, AuthProvider };