import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import moment from 'moment';

import './edit-device.styles.scss'

import api from '../../../api'
import MultiselectInput from '../../multiselect-input/multiselect-input.component'
import { Grid } from '@material-ui/core'
import SelectInput from '../../select-input/select-input.component'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const EditDevice = (props) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState({})
  const [defaulValueDescription, setDefaultValueDescription] = useState({
    "Tipo": "",
    "Situação": "Ativo",
    "Id Estação": deviceId || "",
    "Responsável": institutionArea?.nome || "",
    "Inicio da Operação": initialDate ? moment(initialDate).format('YYYY-MM-DD H:mm:ss') : "",
    // "Fim da Operação": finalDate ? moment(finalDate).format('YYYY-MM-DD H:mm:ss') : "",
    ...(finalDate && {"Fim da Operação": moment(finalDate).format('YYYY-MM-DD H:mm:ss')})
  })
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [deviceId, setDeviceId] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [parameters, setParameters] = useState([])
  const [contracts, setContracts] = useState([])
  const [contractsOptions, setContractsOptions] = useState([])
  const [parametersOptions, setParametersOptions] = useState([])

  const [institutionsList, setInstitutionsList] = useState([{
    id: 0,
    name: ''
}]);
  const [institutionAreaList, setInstitutionAreaList] = useState([])
  const [applicationAreaList, setApplicationAreaList] = useState([])
  const [contractList, setContractList] = useState([])
  const [parameterList, setParameterList] = useState([])

  const [institution, setInstitution] = useState({})
  const [institutionArea, setInstitutionArea] = useState({})
  const [applicationArea, setApplicationArea] = useState(0)
  const [contract, setContract] = useState(0)

  const [device, setDevice] = useState({})

  const [isJsonValid, setIsJsonValid] = useState(true);
  const [successOpen, setSuccessOpen] = useState(false)
  const [failureOpen, setFailureOpen] = useState(false)
  const [alertError, setAlertError] = useState(
    'Por favor, verifique se todos os campos estão preenchidos corretamente.',
  )
  const [loading, setLoading] = useState(false)



  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await api.get(`/dispositivo/?id=${props.editDevice.dispid.id}&application_area_id=${props.editDevice.dispid.application_area_id}&area_institution_id=${props.editDevice.dispid.area_institution_id}&institution_id=${props.editDevice.dispid.institution_id}&ecosystem_id=sima`)
        setDevice(data)

        setDeviceId(data.dispid.id);
        setName(data.nome);
        setInitialDate(moment(data.dataDeCriacao).format('YYYY-MM-DD H:mm:ss'));
        setFinalDate(data.fimDaOperacao !== null? moment(data.fimDaOperacao).format('YYYY-MM-DD H:mm:ss'):null);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        setDescription(JSON.stringify(data.descricao, undefined, 4));
        setContracts(data.contratos.map((item) => item.id));
        setParameters(data.sensorInDevice.map((item) => item.id));
        
        const getInstitutionList = await api.get('/instituicao/usuario')
        setInstitutionsList(getInstitutionList.data)

        const newValues = getInstitutionList.data.map(item => {
          return {
              id: item.id,
              name: item.nome
          }
        });
        setInstitutionsList(newValues);

        // console.log(newValues);
        
        const getAreaInstitutionList = await api.get(`/instituicao/areas/${data.dispid.institution_id}`)
        setInstitutionAreaList(getAreaInstitutionList.data)

        // console.log(getAreaInstitutionList.data)

        const institutionAreaList = getAreaInstitutionList.data.map(item => {
          return {
              id: item.areaid.id,
              name: item.nome
          }
        });
        setInstitutionAreaList(institutionAreaList);

        // console.log(institutionAreaList);

        const getApplicationAreaList = await api.get(`/instituicao/areas/aplicacao/${data.dispid.institution_id}/${data.dispid.area_institution_id}`)
        setApplicationAreaList(getApplicationAreaList.data)
        // console.log(getApplicationAreaList)

        const applicationAreaList = getApplicationAreaList.data.map(item => {
          return {
              id: item.areaDeAplicacaoid.id,
              name: item.nome
          }
        });
        setApplicationAreaList(applicationAreaList);

       // console.log(device)

        const getContractList = await api.get('/importar/contrato/lista')
        // console.log(getContractList.data)
        setContractsOptions(
          getContractList.data.map((item, index) => {
              return {
                  'id': item.id,
                  'value': item.id,
                  'label': item.descricao
              }
        }));

        const getAllSensors = await api.get("/sensor/todos?notPageable=true");
        // const parameters = await JSON.parse(request.data.parametros);
        // console.log(getAllSensors.data.content)
        setParametersOptions(
          getAllSensors.data.content.map((item, index) => {
              return {
                  'id': item.id,
                  'value': item.nomeFantasia.valor,
                  'label': item.nomeFantasia.valor
              }
        }));
        
      // // console.log(parametersOptions)
        // // console.log(contractsOptions)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') return

    setSuccessOpen(false)
    setFailureOpen(false)
    setLoading(false)
  }



  const handleInstitutionChange = async (event, newValue) => {
    setInstitutionArea({})
    setApplicationArea({})
    if (!newValue) {
      setInstitution({})
      setInstitutionAreaList([])
      setApplicationAreaList([])
      return
    }

    setInstitution(newValue)
    try {
      const { data } = await api.get(`/instituicao/areas/${newValue.id}`)
      setInstitutionAreaList(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleInstitutionAreaChange = async (event, newValue) => {
    setApplicationArea({})
    if (!newValue) {
      setInstitutionArea({})
      setApplicationAreaList([])
      return
    }
    setInstitutionArea(newValue)

    try {
      const { data } = await api.get(
        `/instituicao/areas/aplicacao/${institution.id}/${newValue.areaid.id}`,
      )
      setApplicationAreaList(data)
    } catch (e) {
      console.log(e)
    }
  }

  const handleApplicationAreaChange = (event, newValue) => {
    // // console.log(newValue.areaDeAplicacaoid)
    const defaultDescription = {
      "Tipo": newValue?.nome || "",
      "Situação": "Ativo",
      "Id Estação": deviceId || "",
      "Responsável": institutionArea?.nome || "",
      "Inicio da Operação": initialDate ? moment(initialDate).format('YYYY-MM-DD H:mm:ss') : "",
      // "Fim da Operação": finalDate ? moment(finalDate).format('YYYY-MM-DD H:mm:ss') : "",
      ...(finalDate && {"Fim da Operação": moment(finalDate).format('YYYY-MM-DD H:mm:ss')})
    }
    if (!newValue) return setApplicationArea({})
    setApplicationArea(newValue)    
    setDefaultValueDescription(defaultDescription)
    setDescription(defaultDescription)

    
  }

  const handleDescriptionChange= (event) => {
    const value = event.target.value;
    setDescription(value);
    try {
      JSON.parse(value);
      setIsJsonValid(true);
    } catch (error) {
      console.log(error.message)
      setIsJsonValid(false);
    }
  }

  // const handleContractsChange = (selecteds) => {
  //   console.log(selecteds);
  //   const newValues = selecteds.map((item) => item.id);
  //   console.log("NEW_VALUES: "+newValues);
  //   setContracts([...contracts, newValues]);
  // }

  const handleSave = async (event) => {
    event.preventDefault();
    setLoading(true)

    const data = {
      ativo: true,
      dataDeCriacao: initialDate ? moment(initialDate).format('YYYY-MM-DD H:mm:ss'):null,
      descricao: JSON.parse(description),
      dispid: {
        application_area_id: device.areaDeAplicacao.areaDeAplicacaoid.id,
        area_institution_id: device.area.areaid.id,
        ecosystem_id: 'sima',
        id: deviceId,
        institution_id: device.instituicao.id,
      },
      fimDeOperacao: finalDate ? moment(finalDate).format('YYYY-MM-DD H:mm:ss'):null,
      latitude: latitude,
      listaIdContratos: device.contratos.map((item) => item.id),
      listaIdSensores: device.sensorInDevice.map((item) => item.id),
      longitude: longitude,
      nome: name,
    }
    //console.log("DATA_UPDATE: "+JSON.stringify(data, undefined, 4));
    try {
      await api.put('/dispositivo/atualiza', JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' },
      })
      setLoading(false)
      setSuccessOpen(true)
    } catch (e) {
      console.log(e)
      setLoading(false)
      if (e.hasOwnProperty('response')) setAlertError(e.response.data)
      else
        setAlertError(
          'Verifique se todos os campos estão preenchidos corretamente!',
        )
      setFailureOpen(true)
    }
  }

  return (
    <div style={{ width: '100%'}}>
      <form onSubmit={handleSave}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="name"
              label="Nome da estação"
              placeholder="Exemplo"
              InputLabelProps={{
                shrink: true,
              }}
              required
              value={name}
              fullWidth
              margin="normal"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="date-initial"
              label="Data inicial"
              type="datetime-local"
              fullWidth
              required
              InputLabelProps={{
                shrink: true,
              }}
              value={initialDate}
              margin="normal"
              variant="outlined"
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="date-final"
              label="Data final"
              type="datetime-local"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              value={finalDate}
              margin="normal"
              variant="outlined"
              onChange={(e) => setFinalDate(e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="latitude"
              label="Latitude"
              placeholder="-00.0000000"
              InputLabelProps={{
                shrink: true,
              }}
              required
              value={latitude}
              margin="normal"
              variant="outlined"
              onChange={(e) => setLatitude(e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={6} sm={4} md={2}>
            <TextField
              id="longitude"
              label="Longitude"
              placeholder="-00.000000"
              InputLabelProps={{
                shrink: true,
              }}
              required
              value={longitude}
              margin="normal"
              variant="outlined"
              onChange={(e) => setLongitude(e.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4}>
            <SelectInput
                label='institution'
                labelTitle='Instituição'
                values={institutionsList}
                value={props.editDevice.dispid.institution_id}
                handleChange={handleInstitutionChange}
                disabled={true}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <SelectInput
                label='areaInstitution'
                labelTitle='Área da Instituição'
                values={institutionAreaList}
                value={props.editDevice.dispid.area_institution_id}
                handleChange={handleInstitutionAreaChange}
                disabled={true}
            />
          </Grid>
          <Grid item xs={6} sm={4} >
            <SelectInput
                label='areaApplication'
                labelTitle='Área da Aplicação'
                values={applicationAreaList}
                value={props.editDevice.dispid.application_area_id}
                handleChange={handleApplicationAreaChange}
                disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={4} md={4}>
            <MultiselectInput
              label="Sensores"
              placeholder="Selecionar sensores"
              options={parametersOptions}
              isMulti
              onChange={(selected) => setParameters(selected)}
              allowSelectAll={false}
              value={parameters}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <MultiselectInput
              label="Contratos"
              placeholder="Selecionar contratos"
              options={contractsOptions}
              isMulti
              onChange={(selected) => setContracts(selected)}
              allowSelectAll={false}
              value={contracts}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="descricao"
              label="Descrição"
              InputLabelProps={{
                shrink: true,
              }}
              value={description}
              fullWidth
              multiline
              minRows={4}
              margin="normal"
              variant="outlined"
              error={!isJsonValid}
              helperText={!isJsonValid ? 'Formato inválido, verifique se o valor esta entre aspas duplas ou falta alguma virgula' : ''}
              onChange={(e) => handleDescriptionChange(e)}
            />
          </Grid>
        </Grid>
        <div className='button-wrapper multiples'>
            <Button variant='contained' color='primary' type='submit' >
                SALVAR
            </Button>
        </div>
      </form>
      <Snackbar
        open={successOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleAlertClose} severity="success">
          A estação foi salva com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={failureOpen}
        autoHideDuration={5000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleAlertClose} severity="error">
          {alertError}
        </Alert>
      </Snackbar>

      <Snackbar
        open={loading}
        autoHideDuration={null}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        style={{ zIndex: '9999' }}
      >
        <Alert onClose={handleAlertClose} severity="info">
          <CircularProgress size={12} color="secondary" /> &nbsp; Carregando...
        </Alert>
      </Snackbar>
    </div> 
  );
}

export default EditDevice
