import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Box from '@material-ui/core/Box';

import './loginpage.styles.scss';

import LoginForm from '../../components/login-form/login-form.component';
import Signature from '../../components/signature/signature.component';

import { Context } from '../../context/AuthContext';

const LoginPage = () => {
    const { handleLogin } = useContext(Context);

    return (
        <Box className='login-wrapper'>
            <Helmet>
                <title>SIMA - Sistema de Monitoramento Ambiental</title>
                <meta name="description" content="Login Page" />
            </Helmet>
            <Box>
                <LoginForm handleLogin={handleLogin} />
            </Box>
            <Box className='signature'>
                <Signature/>
            </Box>
        </Box>
    );
}

export default LoginPage;
