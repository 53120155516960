import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import TableCustomSort from '../table-custom-sort/table-custom-sort.component';
import ChartPlotter from '../chart/chart-plotter.component';
import Loading from '../loading/loading.component';

import './requests.styles.scss';
import api from '../../api';

const Requests = () => {
    const [loadingData, setLoadingData] = useState(true);
    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);
    const [chart, setChart] = useState(false);
    const [chartData, setChartData] = useState({});
    const [searchField, setSearchField] = useState('');
    const [limite, setLimite] = useState(0);
    const [showElement, setShowElement] = useState(true)
    const productTypes = ['Gerar Gráfico', 'Baixar CSV', 'Baixar XLSX'];

    useEffect(() => {
        (async () => {
            try {
                const request = await api.get('/job/lista?pageNo=0&pageSize=5&sortBy=dataCriacao');
                setCount(request.data.totalElements);
                updateTableData(request.data.content);
                setLoadingData(false);
            } catch (e) {
                console.log(e);
            }
        })()
    }, []);

    const updateTableData = (data) => {
        const formattedData = data.map(item => ({
            id: item.id,
            name: item.nomeJob,
            initial_date: item.dataInicialRequisicao,
            final_date: item.dataFinalRequisicao,
            status: item.status.nome,
            status_id: item.status.id,
            product: productTypes[item.tipoJob.id - 1],
            type: item.tipoJob.id
        }
        ));
        setTableData(formattedData);
    }

    const handleSearch = async (event) => {
        event.preventDefault();
        try {
            const request = await api.get(`/job/lista?pageNo=0&pageSize=5&sortBy=dataCriacao&search=${searchField}`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(0);
        } catch (e) {
            console.log(e);
        }
    }

    const handleChangePage = async (event, newPage) => {
        try {
            const request = await api.get(`/job/lista?pageNo=${newPage}&pageSize=5&sortBy=dataCriacao&search=${searchField}`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(newPage);
        } catch (e) {
            console.log(e);
        }
    }

    const handleChart = async (event, id) => {
        event.preventDefault();
        try {
            const request = await api.get(`/job/chart/${id}`);
            console.log(request)
            const series = request.data.response;
            //console.log(series)
            series.map(item => {
                delete item.type;
            });
            setChartData({
                series: series,
                options: {
                    chart: {
                        type: 'area',
                        stacked: false,
                        height: 350,
                        zoom: {
                            type: 'x',
                            enabled: true,
                            autoScaleYaxis: true
                        },
                        toolbar: {
                            autoSelected: 'zoom'
                        },
                        animations: {
                            enabled: false,
                            dynamicAnimation: {
                                enabled: false,
                            }
                        },

                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 0,
                    },
                    title: {
                        text: request.data.layout.title,
                        align: 'left'
                    },
                    /*fill: { //alteração das cores gradiente
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 0,
                            stops: [0, 90, 100]
                        },
                    },*/
                    annotations: {
                        yaxis: [
                            {
                                y: limite,
                                strokeDashArray: 0,
                                borderColor: "#d60606",
                                fillColor: "#c2c2",
                                opacity: 0.8,
                                offsetX: 0,
                                offsetY: 0,


                            },
                            /* {
                             criar mais de um limite
                                 y: 500,
                                 strokeDashArray: 0,
                                 borderColor: "#d60606",
                                 fillColor: "#c2c2",
                                 opacity: 0.8,
                                 offsetX: 0,
                                 offsetY: 0,
 
 
                             }*/
                        ]


                    },
                    grid: {
                        yaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },

                    xaxis: {
                        type: 'datetime',
                        labels: {
                            formatter: function (value) {
                                return moment(value).format('DD/MM/YYYY HH:mm');
                            }
                        }
                    },


                }
            });
            setChart(true);
        } catch (e) {
            console.log(e);
        }
    }

    const handleCancelChart = e => {
        e.preventDefault();
        setChartData({});
        setChart(false);
    }

    const onChangeText = e => {
        setLimite(e.target.value)
    }
    const redefineLimite = () => {

        setChart(false);
        setShowElement(true);
    }
    const defineLimite = () => {
        if (chart === true) {
            setShowElement(true);
        }
        setChart(false);
        setShowElement(false);
    }

    return (
        <div style={{ width: '100%' }}>


            {showElement ?
                <Box display='flex' alignItems='center'>
                    <TextField
                        id='limite'
                        label='Limite'
                        placeholder='Defina um limite'

                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin='normal'
                        variant='outlined'
                        onChange={onChangeText}
                        padding='10px'
                    />
                    <Button type='submit' onClick={defineLimite} variant='contained' color='primary' style={{ maxHeight: '2.375rem', margin: '10px' }} >
                        Definir Limite
                    </Button>
                </Box>
                : (
                    <Button type='submit' onClick={redefineLimite} variant='contained' color='primary' style={{ maxHeight: '2.375rem' }} >
                        redefinir Limite
                    </Button>

                )
            }



            {chart ?
                <ChartPlotter data={chartData} handleCancelChart={handleCancelChart} /> : (
                    <div>
                        <form onSubmit={handleSearch}>
                            <Grid container spacing={2} className={'grid-search-input'}>
                                <Grid item xs={5}>
                                    <TextField
                                        id='user'
                                        label='Buscar'
                                        placeholder='Buscar requisição'
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        margin='normal'
                                        variant='outlined'
                                        onChange={e => setSearchField(e.target.value)}
                                    />

                                </Grid>

                                <Grid item xs={7}>
                                    <Box display='flex' height='100%' alignItems='center'>
                                        <Button type='submit' variant='contained' color='primary' style={{ maxHeight: '2.375rem' }} >
                                            BUSCAR
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </form>

                        {
                            loadingData
                                ?
                                (<Loading />)
                                :
                                <TableCustomSort
                                    page={page}
                                    rowsPerPage={5}
                                    count={count}
                                    handleChangePage={handleChangePage}
                                    data={tableData}
                                    handleChart={handleChart}
                                />
                        }
                               
                    </div>

                )}

        </div>

    );
}

export default Requests;