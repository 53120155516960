import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';


import SelectInput from '../../select-input/select-input.component';
import MultiselectInput from '../../multiselect-input/multiselect-input.component';
import api from '../../../api';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const BiologicalFilter = (props) => {

    useEffect(() => {
        (async () => {
            try {
                const request = await api.get('/instituicao/filtro/4');
                const parameters = await JSON.parse(request.data.parametros);
                props.setBiologicalMultiSelectValues(parameters);
                
            } catch(e) {
                console.log(e);
            }
        })()
    }, [])

    const [open, setOpen] = React.useState(false);
    const [valores, setValores] = React.useState();
    let teste=[];

    const handleClickOpen = () => {
      setOpen(true);
        teste.push(`( `)
        props.phylum.find((phys)=>{
        teste.push(` ${phys.value} `)
        return setValores(teste)
      })
      teste.push(` )`)
      
      teste.push(` - ( `)
      props.grade.find((phys)=>{
        teste.push(` ${phys.value} `)
        return setValores(teste)
        
      })
      teste.push(` )`)

      teste.push(` - ( `)
      props.order.find((phys)=>{
        teste.push(` ${phys.value} `)
        return setValores(teste)
      })
      teste.push(` )`)
      teste.push(` - ( `)
      props.depth.find((phys)=>{
        teste.push(` ${phys.value} `)
        return setValores(teste)
      })
      teste.push(` )`)
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={4} md={3}>
                    <SelectInput
                        label='tipodado'
                        labelTitle='Tipo de Dado'
                        values={[{id: 'Fitoplâncton', name: 'Fitoplâncton'}, {id: 'Zooplâncton', name: 'Zooplâncton'}]}
                        default='Fitoplâncton'
                        value={props.dataType}
                        handleChange={props.handleDataType}
                    />
                    
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <MultiselectInput 
                        label='Filo'
                        placeholder='Selecionar Filo'
                        options={props.phylumOptions}
                        isMulti
                        onChange={selected => props.setPhylum(selected)}
                        allowSelectAll={true}
                        value={props.phylum}  
                    />
                     
                </Grid>
                    
                <Grid item xs={12} sm={4} md={3}>
                    <MultiselectInput 
                        label='Classe'
                        placeholder='Selecionar Classe'
                        options={props.gradeOptions}
                        isMulti
                        onChange={selected => props.setGrade(selected)}
                        allowSelectAll={true}
                        value={props.grade}
                    />
                   
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MultiselectInput 
                        label='Ordem'
                        placeholder='Selecionar Ordem'
                        options={props.orderOptions}
                        isMulti
                        onChange={selected => props.setOrder(selected)}
                        allowSelectAll={true}
                        value={props.order}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MultiselectInput
                        label='Profundidade'
                        placeholder='Selecionar Profundidade'
                        options={props.depthOptions}
                        isMulti
                        onChange={selected => props.setDepth(selected)}
                        allowSelectAll={true}
                        value={props.depth}
                    />
                </Grid>
            </Grid>
        
            <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Verificar Valores Selecionados
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verificando Filtros"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {valores}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>

        </div>
        
    )

}

export default BiologicalFilter;