import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

import MultiselectInput from '../../multiselect-input/multiselect-input.component';
import api from '../../../api';

const QuimicalPhysicalFilter = (props) => {

    useEffect(() => {
        (async () => {
            try {
                const request = await api.get('/instituicao/filtro/1');
                const parameters = await JSON.parse(request.data.parametros);
                props.setQuimicalPhysicalMultiSelectValues(parameters);
            } catch(e) {
                console.log(e);
            }
        })()
    }, [])

    const [open, setOpen] = React.useState(false);
    const [valores, setValores] = React.useState();
    let teste=[];

    const handleClickOpen = () => {
        setOpen(true);
          teste.push(`( `)
          props.parameters.find((phys)=>{
          teste.push(` ${phys.value} `)
          return setValores(teste)
        })
        teste.push(` )`)
        
        teste.push(` - ( `)
        props.depth.find((phys)=>{
          teste.push(` ${phys.value} `)
          return setValores(teste)
          
        })
        teste.push(` )`)
  
        teste.push(` - ( `)
        props.sampleNature.find((phys)=>{
          teste.push(` ${phys.value} `)
          return setValores(teste)
        })
        teste.push(` )`)
    }
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <MultiselectInput 
                        label='Parâmetros'
                        placeholder='Selecionar Parâmetros'
                        options={props.parametersOptions}
                        isMulti
                        onChange={selected => props.setParameters(selected)}
                        allowSelectAll={true}
                        value={props.parameters}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MultiselectInput
                        label='Profundidade'
                        placeholder='Selecionar Profundidade'
                        options={props.depthOptions}
                        isMulti
                        onChange={selected => props.setDepth(selected)}
                        allowSelectAll={true}
                        value={props.depth}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <MultiselectInput 
                        label='Natureza da Amostra'
                        placeholder='Selecionar Natureza'
                        options={props.sampleNatureOptions}
                        isMulti
                        onChange={selected => props.setSampleNature(selected)}
                        allowSelectAll={true}
                        value={props.sampleNature}
                    />
                </Grid>
            </Grid>

            <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Verificar Valores Selecionados
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Verificando Filtros"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {valores}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </div>

        </div>
        
    )

}

export default QuimicalPhysicalFilter;