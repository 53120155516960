import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import Button from '@material-ui/core/Button';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';


const ChartPlotter = (props) => {

  const [selectedValue, setSelectedValue] = React.useState('null');

  const handleChange = (event) => {
    console.log(event.target.value)
    setSelectedValue(event.target.value);


  };

  return (

    
    <div id='chart' style={{ overflow: 'hidden' }}>

       {(selectedValue === 'null') ?
      <RadioGroup>
      <FormControlLabel value='area' control={<Radio/>} label='Gáfico de Área' onChange={handleChange} checked={selectedValue === 'area'}> </FormControlLabel>
      <FormControlLabel value='bar' control={<Radio/>} label='Gráfico de Barras' onChange={handleChange} checked={selectedValue === 'bar'}> </FormControlLabel>
      <FormControlLabel value='line' control={<Radio/>} label='Gráfico Linha' onChange={handleChange} checked={selectedValue === 'line'}> </FormControlLabel>

      
      </RadioGroup>
      :console.log('nulo')}
      
      {console.log('props',props)}
      {(selectedValue != 'null') ?
        <Chart options={props.data.options} series={props.data.series} type={selectedValue} height={350} yaxis={2000} />
        : console.log('nulo')}

      <div style={{ width: '100px' }}>



        <Button onClick={props.handleCancelChart} variant='contained' color='primary' fullWidth display >
          Voltar
        </Button>



      </div>
    </div>
  );
}

export default ChartPlotter;