import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';


import { Context } from './context/AuthContext';

import HomePage from './pages/home/homepage.component';
import Cards from './pages/ListCards/Cards';
import LoginPage from './pages/login/loginpage.component';


function CustomRoute({ isPrivate, ...rest }) {
    const { authenticated, loading } = useContext(Context);

   if(loading)
        return <div></div>;

    if(isPrivate && !authenticated)
        return <Redirect to='/login' />

    return <Route {...rest} />
}

export default function Routes() {
    return(
        <Switch>
            <CustomRoute exact path='/login' component={LoginPage} />
            <CustomRoute isPrivate path='/' component={HomePage} />

        </Switch>
    );
}