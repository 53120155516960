
import { useState, useEffect } from 'react';

import './admin.styles.scss';
import api from '../../api';

import TabsPanel from '../tabs-panel/tabs-panel.component';
import ProfileForm from './profile-form/profile-form.component';
import UserList from './user-list/user-list.component';
import Permissions from './permissions/permissions.component';

const Admin = () => {
    const [userMaxLevel, setUserMaxLevel] = useState(0);
    const [tab, setTab] = useState(0);
    const [editPermissions, setEditPermissions] = useState({
        id: 0,
        name: '',
    });
    
    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get('/usuario/metadados');
                const maxLevelAccess = data.vinculos.map(item => item.perfil.levelaccess).reduce(function(a, b) {
                    return Math.max(a, b);
                });
                setUserMaxLevel(maxLevelAccess);
            } catch(e) {
                console.log(e)
            }
        })();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    }

    return (
        <TabsPanel
            tabsComponents={[
                <ProfileForm title='Perfil' show />,
                <UserList
                    title='Lista de usuários'
                    show={userMaxLevel > 6 ? true : false}
                    handleChangeTab={handleChangeTab}
                    setEditPermissions={setEditPermissions}
                    userMaxLevel={userMaxLevel}
                />,
                <Permissions title='Permissões' editPermissions={editPermissions} show={editPermissions.id > 0 ? true : false} />
            ]}
            handleChangeTab={handleChangeTab}
            tab={tab}
        />
    );
}

export default Admin;