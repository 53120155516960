import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';

import FilterForm from '../filter-form/filter-form.component';

import useWindowDimensions from '../../hooks/useWindowDimensions';

const DrawerNav = ({ open, setOpenDrawer, drawerWidth, handleRenderedMainPanel }) => {
    const { width } = useWindowDimensions();

    const useStyles = makeStyles((theme) => ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            
        },
        drawerPaper: {
            width: '280px',
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
            },
            backgroundColor: '#F5F52',
            boxSizing: 'border-box',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        }
    }));

    const classes = useStyles();
    
    return (
        <Box component='nav'>
            <Drawer
                variant={ width < 960 ? 'temporary' : 'persistent' }
                anchor='left'
                open={open}
                onClose={() => setOpenDrawer(!open)}
                ModalProps={{
                    keepMounted: true,
                }}
                classes={{
                    paper: classes.drawerPaper,
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                }}
            >
                <div className={classes.drawerHeader} />
                <FilterForm handleRenderedMainPanel={handleRenderedMainPanel} />
                
                
                
            </Drawer>
            
        </Box>
        
    );
}

export default DrawerNav;