import React, { useState, useEffect, useContext, createContext } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

import { Context } from '../../context/MainContext';

import api from '../../api';

import './filter-form.styles.scss';
import Treeview from '../treeview/treeview.component';
import { Divider } from '@material-ui/core';

export const TypeContext = createContext();

export const ContextRotas = ({ children }) => {
    let rota;
    let type;
    if (window.location.href === `${window.location.protocol}//${window.location.host}/AguasSubterraneas`) {
        rota = '/AguasSubterraneas'
        type = 'Águas Subterrâneas'
    }
    else if (window.location.href === `${window.location.protocol}//${window.location.host}/QualidadeAgua`) {
        rota = '/QualidadeAgua'
        type = 'Qualidade de Água'
    }
    else if (window.location.href === `${window.location.protocol}//${window.location.host}/Meteorologico`) {
        rota = '/Meteorologico'
        type = 'Meteorológico'
    }
    else if (window.location.href === `${window.location.protocol}//${window.location.host}/Sedimentos`) {
        rota = '/Sedimentos'
        type = 'Sedimentos'
    }
    else if (window.location.href === `${window.location.protocol}//${window.location.host}/Hidropedologicas`) {
        rota = '/Hidropedologicas'
        type = 'Hidropedológicas'
    }
    else if (window.location.href === `${window.location.protocol}//${window.location.host}/Hidrossedimentologicas`) {
        rota = '/Hidrossedimentologicas'
        type = 'Hidrossedimentológicas'
    }
    else if (window.location.href === `${window.location.protocol}//${window.location.host}/Agrometeorologicas`) {
        rota = '/Agrometeorologicas'
        type = 'Agrometeorológicas'
    }

    return (
        <TypeContext.Provider value={{ rota, type }}>
            {children}
        </TypeContext.Provider>
    )
}

const FilterForm = ({ handleRenderedMainPanel }) => {
    const tipoRota = useContext(TypeContext);
    //console.log(`Tipo da estaçao =${tipoRota.type}`)

    const { handleDrawerFilter, handleDrawerClear } = useContext(Context);

    const [subBasin, setSubBasin] = useState({});
    const [city, setCity] = useState({});

    const [subBasinList, setSubBasinList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);

    const [stationType, setStationType] = useState([tipoRota.type]);
    const [stationName, setStationName] = useState('');
    const [arvore, setArvore] = useState(0)




    useEffect(() => {
        (async () => {
            try {
                const getSubBasinsList = await api.get('/mapa/subbacia');
                setSubBasinList(getSubBasinsList.data);

                const getCitys = await api.get('/mapa/municipio');
                setCitiesList(getCitys.data);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const handleClear = (event) => {
        window.setTimeout(1000)
        handleDrawerClear();
        setStationType([[tipoRota.type]]);
        setStationName('');
        setCity({});
        setSubBasin({});
        setArvore(0);
    }

    const handleClick = (event) => {
        window.setTimeout(1000)
        setArvore(1);
    }

    return (
        <div className='filter-form-wrapper'>

            <form onSubmit={e => handleDrawerFilter(e, stationType, stationName, subBasin, city)}>
                <Box marginTop='16px' marginBottom='24px'>
                    <Autocomplete
                        disablePortal
                        id='subbasins'
                        options={subBasinList}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                placeholder='Sub-bacias'
                                variant='outlined'
                                label='Sub-bacias'
                            />
                        )}
                        getOptionLabel={(option) => option.nome || ''}
                        onChange={(e, value) => {
                            if (value) setSubBasin(value)
                            else setSubBasin({})
                        }}
                        value={subBasin}
                    />
                </Box>
                <Box marginTop='16px' marginBottom='12px'>
                    <Autocomplete
                        disablePortal
                        id='city'
                        options={citiesList}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                placeholder='Município'
                                variant='outlined'
                                label='Município'
                            />
                        )}
                        getOptionLabel={(option) => option.nome || ''}
                        onChange={(e, value) => {
                            if (value) setCity(value)
                            else setCity({})
                        }}
                        value={city}
                    />
                </Box>

                <TextField
                    id='station'
                    label='Estação'
                    placeholder='Procurar por uma estação'
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin='normal'
                    variant='outlined'
                    onChange={e => setStationName(e.target.value)}
                    value={stationName}

                />
                <div className='buttons'>
                    <Button onClick={handleClear} variant='contained' color='secondary' fullWidth>
                        LIMPAR
                    </Button>
                    <Button onClick={handleClick} type='submit' variant='contained' color='primary' fullWidth >
                        BUSCAR
                    </Button>


                </div>
                <Divider />

                {(arvore === 1) ? <Treeview handleRenderedMainPanel={handleRenderedMainPanel} id='arv' /> : null}


            </form>
        </div>
    );
}

export default FilterForm;