import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';

const Input = withStyles((theme) => ({
    root: {
      'label + &': {
        width: '100%',
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      top: '-1px',
      backgroundColor: theme.palette.secondary.main,
      padding: '18.5px 14px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        padding: '17.5px 13px',
        borderRadius: 4,
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
        backgroundColor: theme.palette.secondary.main,
        boxSizing: 'border-box'
      },
    },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    selectLabel: {
        position: 'absolute',
        top: '-8px',
        left: '8px',
        zIndex: 2,
        color: '#3A3A3A',
        fontWeight: 500,
        backgroundColor: theme.palette.secondary.main,
        padding: '0 0.5rem',
    }
}));

const SelectInput = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel className={classes.selectLabel}>{props.labelTitle}</InputLabel>
            <Select
                labelId={props.label}
                id={props.label}
                value={props.value}
                onChange={props.handleChange}
                input={<Input />}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
            >
                
                {props.values.map((value) => (
                    <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SelectInput;