import axios from 'axios';
import history from './history';

const axiosInstance = axios.create({
  baseURL: `https://${window.location.host}/api`,
  // baseURL: `https://[2801:b6:400:606::22d]/api`,
  // baseURL: `http://localhost:8080/`,
});

axiosInstance.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        localStorage.removeItem('token');
        axiosInstance.defaults.headers.Authorization = undefined;
        history.push('/login');
    }
    return Promise.reject(error);
  });

export default axiosInstance;
