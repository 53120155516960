import Box from '@material-ui/core/Box';
import './App.css';
import { Router } from 'react-router-dom';

import Routes from './routes';
import history from './history';

import { AuthProvider } from './context/AuthContext';
import { ContextRotas } from './components/filter-form/filter-form.component';

const App = () => {
  return (
    <AuthProvider>
      <ContextRotas>
      <Box className='main'>
        <Router history={history}>
          <Routes />
        </Router>
      </Box>
      </ContextRotas>
    </AuthProvider>
  );
}

export default App;
