import weather from '../../assets/icon-weather.png'
import sediments from '../../assets/icon-sediments.png'
import water from '../../assets/icon-water.png'
import drink from '../../assets/icon-drink.png'
import agro from '../../assets/icon-agrometeorologicas.svg'
import hidro from '../../assets/icon-hidropelogia.svg'
import hidroSediments from '../../assets/icon-hidrossedimentologicas.svg'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  },
  root: {
    flexGrow: 1,
    maxWidth: 1080,
    padding: 16
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#00CCC0',
    minHeight: 140,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'all .3s',
    '&:hover': {
      backgroundColor: '#02c1b6'
    },
    '& > h3': {
      marginBottom: 0
    }
  },
}))

function Cards() {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs>
            <a href="/QualidadeAgua" title="Qualidade de Água">
              <Paper className={classes.paper}>
                <img src={drink} alt="" />
                <h3>Qualidade de Água</h3>
              </Paper>
            </a>
          </Grid>
          <Grid item xs>
            <a href="/Meteorologico" title="Meteorologico">
              <Paper className={classes.paper}>
                <img src={weather} alt="" />
                <h3>Meteorológico</h3>
              </Paper>
            </a>
          </Grid>
          <Grid item xs>
            <a href="/Sedimentos" title="Sedimentos">
              <Paper className={classes.paper}>
                <img src={sediments} alt="" />
                <h3>Sedimentos</h3>
              </Paper>
            </a>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ paddingTop: 10 }}>
          <Grid item xs>
            <a href="/AguasSubterraneas" title="Águas Subterrâneas">
              <Paper className={classes.paper}>
                <img src={water} alt="" />
                <h3>Águas Subterrâneas</h3>
              </Paper>
            </a>
          </Grid>
          <Grid item xs>
            <a href="/Hidrossedimentologicas" title="Hidrossedimentológicas">
              <Paper className={classes.paper}>
                <img src={hidroSediments} alt="" />
                <h3>Hidrossedimentológicas</h3>
              </Paper>
            </a>
          </Grid>
          <Grid item xs>
            <a href="/Agrometeorologicas" title="Agrometeorologicas">
              <Paper className={classes.paper}>
                <img src={agro} alt="" />
                <h3>Agrometeorológicas</h3>
              </Paper>
            </a>
          </Grid>
          <Grid item xs>
            <a href="/Hidropedologicas" title="Hidropedologicas">
              <Paper className={classes.paper}>
                <img src={hidro} alt="" />
                <h3>Hidropedológicas</h3>
              </Paper>
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Cards