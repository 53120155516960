
import React, { useState, useEffect, useContext } from 'react';
import { MapContainer, TileLayer, LayersControl, WMSTileLayer } from 'react-leaflet';

import { Context } from '../../context/MainContext';

import { MarkerWaterQuality, MarkerDefault } from './marker/marker.component';

import './map.styles.scss';

import api from '../../api';


const Map = () => {
    const { markers, setMap, overlayLayers } = useContext(Context);
    const [layers, setLayers] = useState([]);


    useEffect(() => {
        (async () => {
            try {
                const response = await api.get('/geoserver/layer/filter');
                setLayers(response.data);
                layers.sort();
                console.log(layers)
            } catch (e) {
                console.log(e);

            }
        })()
    }, []);

    function formatNameLayer(layerName) {
        let name = layerName.split('_');
        return name[1];
    }

    const URL_WMS = "https://sima.pti.foz.br/geoserver/wms";

    const LayersTile = () => {
        return (
            layers.map((item, index) => {
                if (item.filters.length > 0 && overlayLayers.hasOwnProperty(item.layerName)) {
                    return item.filters.map(filter => {
                        return (
                            <LayersControl.Overlay name={formatNameLayer(item.layerName)} key={item.layerName} checked={overlayLayers[item.layerName].checked}>
                                <WMSTileLayer
                                    url={URL_WMS}
                                    layers={item.layerName}
                                    format='image/png'
                                    transparent='true'
                                    CQL_FILTER={
                                        `${filter.filterKey} ${filter.filterType === 'integer' ? '=' : 'like'} ${filter.filterKey === 'id_pk' ? overlayLayers[item.layerName].metadata.id : overlayLayers[item.layerName].metadata.codigoMunicipio}`
                                    }
                                />
                            </LayersControl.Overlay>
                        );
                    });
                } else {
                    return (
                        <LayersControl.Overlay name={formatNameLayer(item.layerName)} key={item.layerName} checked={false}>
                            <WMSTileLayer
                                url={URL_WMS}
                                layers={item.layerName}
                                format='image/png'
                                transparent='true'
                            />
                        </LayersControl.Overlay>
                    );
                }
            })
        )
    }

    return (
        <div style={{ height: '100vh' }}>
            <MapContainer center={[-24.567108, -51.751904]} zoom={7} scrollWheelZoom={true} whenCreated={(map) => setMap(map)}>
                <LayersControl position="bottomright" >
                    <LayersControl.BaseLayer checked name="OpenStreetMap - Color">
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer name="OpenStreetMap - Black and White">
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                    <LayersTile />
                </LayersControl>

                {markers.map(marker => {

                    if (marker.hidden) return null

                    if (!marker.hasOwnProperty('log')) return null

                    const metadata = marker.metadados


                        //marcação mapa
                       // console.log(window.location.href)

                    if(window.location.href ===`${window.location.protocol}//${window.location.host}/QualidadeAgua`){

                    if (marker.tipo === 2)
                        return (<MarkerWaterQuality key={marker.id} marker={marker} metadata={metadata} />)
                }

                if(window.location.href ===`${window.location.protocol}//${window.location.host}/Meteorologico`){

                    if (marker.tipo === 1)
                    return (<MarkerDefault key={marker.id} marker={marker} metadata={metadata} />)
                }
                if(window.location.href ===`${window.location.protocol}//${window.location.host}/AguasSubterraneas`){

                    if (marker.tipo === 4)
                    return (<MarkerDefault key={marker.id} marker={marker} metadata={metadata} />)
                }

                if(window.location.href ===`${window.location.protocol}//${window.location.host}/Sedimentos`){

                    if (marker.tipo === 5)
                    return (<MarkerDefault key={marker.id} marker={marker} metadata={metadata} />)
                }
                if(window.location.href ===`${window.location.protocol}//${window.location.host}/Hidrossedimentologicas`){

                    if (marker.tipo === 6)
                    return (<MarkerWaterQuality key={marker.id} marker={marker} metadata={metadata} />)
                }
                if(window.location.href ===`${window.location.protocol}//${window.location.host}/Agrometeorologicas`){

                    if (marker.tipo === 7)
                    return (<MarkerDefault key={marker.id}  marker={marker} metadata={metadata} />)
                }
                if(window.location.href ===`${window.location.protocol}//${window.location.host}/Hidropedologicas`){

                    if (marker.tipo === 8)
                    return (<MarkerDefault key={marker.id} marker={marker} metadata={metadata} />)
                }

                })}
            </MapContainer>
        </div>
    );

};

export default Map;
