import { useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './main-panel.styles.scss';

const Accordion = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        border: '0',
        boxShadow: '0px 3px 7px #00000029',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        marginBottom: -1,
        borderRadius: '4px 4px 0 0',
        minHeight: 38,
        '&$expanded': {
            minHeight: 38,
        },
    },
    content: {
        '&$expanded': {
            margin: '12spx 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const MainPanel = (props) => {
    const theme = useTheme();

    return (
        <Box sx={{ marginLeft: { sm: '0px', md: '420px' }}} className='main-panel-wrapper'>
            <Accordion defaultExpanded={props.defaultExpanded} elevation={1}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{color: theme.palette.primary.contrastText}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{props.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {props.children}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default MainPanel;