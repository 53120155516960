import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';

import UserListTable from './user-list-table/user-list-table.component';
import SelectInput from '../../select-input/select-input.component';
import Loading from '../../loading/loading.component';

import api from '../../../api';

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '2px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 3),
        maxWidth: '500px',
            '& > :first-child': {
            fontWeight: '500',
        },
        '& > :first-child + div': {
            margin: '0.8rem 0',
            fontWeight: '500',
            width: '100%',
        },
        '& > :first-child + div > div': {
            textAlign: 'justify',
            '& > label': {
                backgroundColor: 'white !important',
            },
            '& > div > div': {
                backgroundColor: 'white !important',
            }
        },
    },
    paperNewBond: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '2px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 3),
        maxWidth: '500px',
        '& > :first-child': {
            fontWeight: '500',
        },
        '& > :first-child + div > div': {
            '& > label': {
                backgroundColor: 'white !important',
            },
            '& > div > div': {
                backgroundColor: 'white !important',
            }
        },
      },
}));

const UserList = (props) => {
    const classes = useStyles();
    const [loadingData, setLoadingData] = useState(true);

    const [newUserModalOpen, setNewUserModalOpen] = useState(false);
    const [newBondaModalOpen, setNewBondaModalOpen] = useState(false);

    const [userSearch, setUserSearch] = useState('');

    const [newUserName, setNewUserName] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');

    const [institutionsList, setInstitutionsList] = useState([{
        id: 0,
        name: ''
    }]);
    const [institution, setInstitution] = useState('');
    const [usersNoBondList, setUsersNoBondList] = useState([]);
    const [userNoBond, setUserNoBond] = useState({
        id: 0,
        name: ''
    });

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [alertError, setAlertError] = useState('Por favor, verifique se todos os campos estão preenchidos corretamente.');
    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        (async () => {
            const { data } = await api.get('/instituicao/usuario');
            const newValues = data.map(item => {
                    return {
                        id: item.id,
                        name: item.nome
                    }
                }
            );
            setInstitutionsList(newValues);
            setInstitution(newValues[0].id);

            const userNewBondList = await api.get(`/usuario/todos/sem/vinculo?idIntituicao=${newValues[0].id}`);
            setUsersNoBondList(userNewBondList.data);

            const usersList = await api.get('/usuario/todos/atrelados?pageNo=0&pageSize=5');
            setCount(usersList.data.totalElements);
            updateTableData(usersList.data.content);

            setLoadingData(false);
        })();
    }, []);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
    
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    }

    const handleCreateNewUser = async (event) => {
        event.preventDefault();
        setLoading(true);
        if(newUserName === '' || newUserEmail === '') {
            setFailureOpen(true);
            return
        }

        const data = {
            ativo: true,
            email: newUserEmail,
            nome: newUserName,
        }
        try {
            const response = await api.post('/usuario/novo', JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setLoading(false);
            setSuccessOpen(true);
            setNewUserModalOpen(false);
            setNewUserName('');
            setNewUserEmail('');
            props.setEditPermissions({
                id: response.data.id,
                name: response.data.nome,
            });
            props.handleChangeTab(null, 2);

        } catch(e) {
            if(e.response.data) setAlertError(e.response.data);
            setLoading(false);
            setFailureOpen(true);
        }
    }

    const handleInstitutionChange = async (event) => {
        setInstitution(event.target.value);
        const userNewBondList = await api.get(`/usuario/todos/sem/vinculo?idIntituicao=${event.target.value}`);
        setUsersNoBondList(userNewBondList.data);
    }

    const handleUserNewBondChange = (event, value) => {
        if(value)
            setUserNoBond({
                id: value.id,
                name: value.nome,
                institution: institution
            });
        else
            setUserNoBond({
                id: 0,
                name: ''
            });
    }

    const handleNewBond = (event) => {
        if(userNoBond.id > 0) { 
            setNewBondaModalOpen(false);
            props.setEditPermissions(userNoBond);
            props.handleChangeTab(null, 2);
        } else {
            setAlertError('Selecione um usuário.');
            setFailureOpen(true);
        }
    }

    const updateTableData = (data) => {
        const formattedData = data.map(item => ({
                id: item.id,
                status: item.ativo,
                name: item.nome,
                email: item.email,
                phone: item.telefone,
                idiom: 'PT-BR',
            }
        ));
        setTableData(formattedData);
    }

    const handleChangePage = async (event, newPage) => {
        try {
            const request = await api.get(`/usuario/todos/atrelados?pageNo=${newPage}&pageSize=5`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(newPage);
        } catch(e) {
            console.log(e);
        }
    }

    const handleTableSearch = async (event) => {
        event.preventDefault();
        try {
            const request = await api.get(`/usuario/todos/atrelados?pageNo=0&pageSize=5&search=${userSearch}`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(0);
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby='modal-new-user'
                aria-describedby='modal-new-user-description'
                className={classes.modal}
                open={newUserModalOpen}
                onClose={e => setNewUserModalOpen(false)}
                closeAfterTransition={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={newUserModalOpen}>
                    <Box className={classes.paper} display='flex' flexDirection='column' minWidth={'500px'}>
                        <div>Novo Usuário</div>
                        <TextField
                            name='newUserName'
                            value={newUserName}
                            label='Nome completo'
                            placeholder='Nome'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setNewUserName(e.target.value)}
                        />
                        <TextField
                            name='newUserEmail'
                            value={newUserEmail}
                            label='Email'
                            placeholder='exemplo@exemplo.com'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setNewUserEmail(e.target.value)}
                            style={{ marginBottom: '1rem' }}
                        />
                        <Box display='flex' justifyContent='flex-end' marginTop='0.5rem'>
                            <Button onClick={handleCreateNewUser} variant='contained' color='primary'>
                                CONFIRMAR
                            </Button>
                        </Box>

                        <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity='error'>
                                {alertError}
                            </Alert>
                        </Snackbar>

                        <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity='info'>
                                <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                            </Alert>
                        </Snackbar>
                    </Box>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby='modal-new-bond'
                aria-describedby='modal-new-bond-description'
                className={classes.modal}
                open={newBondaModalOpen}
                onClose={e => setNewBondaModalOpen(false)}
                closeAfterTransition={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={newBondaModalOpen}>
                    <Box className={classes.paperNewBond} display='flex' flexDirection='column' minWidth={'500px'}>
                        <div>Novo Vínculo</div>
                        <Box display='flex' flexDirection='column' marginBottom='1rem'>
                            <SelectInput
                                label='institution'
                                labelTitle='Instituição'
                                values={institutionsList}
                                value={institution}
                                handleChange={handleInstitutionChange}
                            />

                            <div style={{marginTop: '1rem'}}>
                                <Autocomplete
                                    disablePortal
                                    id='userNewBond'
                                    options={usersNoBondList}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{
                                                ...params.InputLabelProps,
                                                shrink: true,
                                            }}
                                            placeholder='Email'
                                            variant='outlined'
                                            label='Usuário'
                                        />
                                    )}
                                    getOptionLabel={(option) => option.email || ''}
                                    onChange={handleUserNewBondChange}
                                />
                            </div>
                        </Box>

                        <Box display='flex' justifyContent='flex-end' marginTop='0.5rem'>
                            <Button onClick={handleNewBond} variant='contained' color='primary'>
                                CONFIRMAR
                            </Button>
                        </Box>

                        <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity='error'>
                                {alertError}
                            </Alert>
                        </Snackbar>

                        <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity='info'>
                                <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                            </Alert>
                        </Snackbar>
                    </Box>
                </Fade>
            </Modal>
            
            <form onSubmit={handleTableSearch}>
                <Grid container spacing={2} style={{marginBottom: '0.313rem'}}>
                    <Grid item xs={12} md={12} lg={5}>
                        <TextField 
                            id='user'
                            label='Buscar usuário'
                            placeholder='Nome'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            value={userSearch}
                            onChange={e => setUserSearch(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={7}>
                        <Box display='flex' height='100%' justifyContent='space-between' alignItems='center'>
                            <Button type='submit' variant='contained' color='primary' style={{maxHeight: '2.375rem'}} >
                                BUSCAR
                            </Button>

                            <div>
                                <Button onClick={e => setNewBondaModalOpen(true)} variant='contained' color='primary' style={{maxHeight: '2.375rem', marginRight: '1rem'}} >
                                    NOVO VÍNCULO
                                </Button>
                                <Button onClick={e => setNewUserModalOpen(true)} variant='contained' color='primary' style={{maxHeight: '2.375rem'}} >
                                    NOVO USUÁRIO
                                </Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </form>

            {  
                loadingData
                ?
                (<Loading />)
                :
                ( <UserListTable 
                    page={page}
                    rowsPerPage={5}
                    count={count}
                    handleChangePage={handleChangePage}
                    data={tableData}
                    handleChangeTab={props.handleChangeTab}
                    setEditPermissions={props.setEditPermissions}
                    userMaxLevel={props.userMaxLevel}
                /> )
            }   

            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    Usuário criado com sucesso!
                </Alert>
            </Snackbar>
        </div>
    )
}

export default UserList;