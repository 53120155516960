import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from '../../../../api';

import {ReactComponent as CheckIcon} from '../../../../assets/upload/checkbox-circle-line.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/upload/close-circle-line.svg';
import {ReactComponent as FileAddIcon} from '../../../../assets/upload/file-add-line.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/admin/delete-bin-5-fill.svg';
import {ReactComponent as DownloadIcon} from '../../../../assets/upload/download-cloud-fill.svg';


const headCells = [
    { id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Nome do arquivo' },
    { id: 'persisted', align: 'center', numeric: false, disablePadding: false, label: 'Persistido' },
    { id: 'stored', align: 'center', numeric: false, disablePadding: false, label: 'Armazenado' },
    { id: 'responsible', align: 'left', numeric: false, disablePadding: false, label: 'Responsável' },
    { id: 'contract', align: 'left', numeric: false, disablePadding: false, label: 'Contrato' },
    { id: 'institution', align: 'left', numeric: false, disablePadding: false, label: 'Instituição' },
    { id: 'areainstitution', align: 'left', numeric: false, disablePadding: false, label: 'Área da Instituição' },
    { id: 'areaapplication', align: 'left', numeric: false, disablePadding: false, label: 'Área da Aplicação' },
    { id: 'actions', align: 'center', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow className={classes.tableHeadBorderless}>
            {headCells.map((headCell) => (
                <TableCell
                key={headCell.id}
                align={headCell.align === 'center' ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                    ) : null}
                </TableSortLabel>
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-of-type > td': {
            borderBottom: '0 !important',
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeadBorderless: {
        '&:last-of-type > th': {
            borderBottom: '0 !important',
        },
    },
    statusOpen: {
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            '-moz-border-radius': '7.5px',
            '-webkit-border-radius': '7.5px',
            borderRadius: '7.5px',
            backgroundColor: '#4CAF50',
            position: 'absolute',
            left: '50%',
            marginLeft: '-16px',
            bottom: '24px',
            cursor: 'pointer',
        },
    },
    statusClosed: {
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            '-moz-border-radius': '7.5px',
            '-webkit-border-radius': '7.5px',
            borderRadius: '7.5px',
            backgroundColor: '#F44336',
            position: 'absolute',
            left: '50%',
            marginLeft: '-16px',
            bottom: '24px',
            cursor: 'pointer',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '2px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 3),
        maxWidth: '500px',
        '& > :first-child': {
            fontWeight: '500',
        },
        '& > :first-child + div': {
            margin: '0.8rem 0',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center',
        },
        '& > :first-child + div + div': {
            textAlign: 'justify',
        },
        '&:focus': {
            outline: 'none !important',
        },
        '& > img': {
            paddingTop: '1rem',
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const UploadListTable = props => {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('initial_date');

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState('');
    const [alertError, setAlertError] = useState('Por favor, verifique se todos os campos estão preenchidos corretamente.');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
          return;
        
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    };

    const handlePersistFile = async (event, item) => {
        if(!item.stored || item.persisted || item.status === 'PROCESSANDO') return;

        setLoading(true);
        try { 
            await api.post(`/importar/insere?ids=${item.id}`);
            setLoading(false);
            setAlertSuccess('Arquivo foi adicionado a fila de processamento com sucesso!');
            setSuccessOpen(true);
            props.handleChangePage(null, 0);
        } catch(e) {
            setLoading(false);
            setAlertError('Erro ao persistir o arquivo.');
            setFailureOpen(true);
            console.log(e);
        }
    }

    const handleDeleteFile = async (event, item) => {
        if(!item.stored || item.persisted || item.status === 'PROCESSANDO') return;

        setLoading(true);
        try { 
            await api.delete(`/importar/deletar?ids=${item.id}`);
            setLoading(false);
            setAlertSuccess('Arquivo deletado com sucesso!');
            setSuccessOpen(true);
            props.handleChangePage(null, 0);
        } catch(e) {
            setLoading(false);
            setAlertError('Erro ao deletar o arquivo.');
            setFailureOpen(true);
            console.log(e);
        }
    }

    const handleDownloadFile = async (event, item) => {
        if(!item.stored || item.persisted || item.status === 'PROCESSANDO') return;

        setLoading(true);
        try {
            const response = await api.get(`/importar/downloadCSV/${item.id}`, { responseType: 'blob' });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${item.name}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setLoading(false);
        } catch(e) {
            setLoading(false);
            setAlertError('Erro ao gerar o download do arquivo.');
            setFailureOpen(true);
            console.log(e);
        }
    }

    return (
        <div className={classes.root}>
            <TableContainer components={{ Container: props => <Paper {...props} elevation={0}/> }}>
                <Table aria-label='panel table' className={'table-container'}>
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                    {props.data.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                                <StyledTableRow
                                    tabIndex={-1}
                                    key={row.id}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell align='center'>
                                        {
                                            row.persisted && row.status !== 'PROCESSANDO' ? 
                                            <CheckIcon fill='currentColor' style={{color: '#00CCC0'}} /> : 
                                            <CloseIcon fill='currentColor' style={{color: '#f44336'}} />
                                        }
                                    </TableCell>
                                    <TableCell align='center'>
                                        {
                                            row.stored && row.status !== 'PROCESSANDO' ? 
                                            <CheckIcon fill='currentColor' style={{color: '#00CCC0'}} /> : 
                                            <CloseIcon fill='currentColor' style={{color: '#f44336'}} />
                                        }
                                    </TableCell>
                                    <TableCell>{row.user.nome}</TableCell>
                                    <TableCell>{row.contractName}</TableCell>
                                    <TableCell>{row.institution}</TableCell>
                                    <TableCell>{row.institutionArea}</TableCell>
                                    <TableCell>{row.applicationArea}</TableCell>
                                    <TableCell align='center'>
                                        <FileAddIcon
                                            fill='currentColor'
                                            style={{
                                                color: '#00CCC0',
                                                cursor: 'pointer',
                                                cursor: row.stored && row.status !== 'PROCESSANDO' ? 'pointer' : '',
                                                opacity: row.stored && row.status !== 'PROCESSANDO' ? '' : '0.5',
                                            }}
                                            onClick={e => handlePersistFile(e, row)}
                                        />
                                        <DownloadIcon 
                                            fill='currentColor'
                                            style={{
                                                color: '#00CCC0',
                                                margin: '0 0.8rem',
                                                cursor: row.stored && row.status !== 'PROCESSANDO' ? 'pointer' : '',
                                                opacity: row.stored && row.status !== 'PROCESSANDO' ? '' : '0.5',
                                            }}
                                            onClick={e => handleDownloadFile(e, row)}
                                        />
                                        <DeleteIcon
                                            fill='currentColor'
                                            style={{
                                                color: '#00CCC0',
                                                cursor: row.stored && row.status !== 'PROCESSANDO' ? 'pointer' : '',
                                                opacity: row.stored && row.status !== 'PROCESSANDO' ? '' : '0.5',
                                            }}
                                            onClick={e => handleDeleteFile(e, row)}
                                        />
                                    </TableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={props.count}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onChangePage={props.handleChangePage}
                rowsPerPageOptions={[5]}
            />

            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    {alertSuccess}
                </Alert>
            </Snackbar>

            <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='error'>
                    {alertError}
                </Alert>
            </Snackbar>

            <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='info'>
                    <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                </Alert>
            </Snackbar>
        </div>
    );
};

export default UploadListTable;