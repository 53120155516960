import ReactDOM from 'react-dom';
import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

const theme = createTheme({
  typography: {
    fontFamily: [
      "'Roboto', sans-serif"
    ],
  },
  palette: {
    primary: {
      main: '#14B8AE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F5F5F5',
      contrastText: '#707070',
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        boxShadow: 'none',

        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        border: '1px solid',
        borderColor: '#14B8AE',
        boxShadow: 'none',

        '&:hover': {
          backgroundColor: '#F5F5F5',
          boxShadow: 'none',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#3A3A3A',
        fontWeight: 500,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #707070',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& > fieldset': {
          borderColor: '#707070',
        },
      },
    },
    MuiSelect: {
      root: {
        border: '1px solid #707070',
      },
    },
    MuiPopover: {
      paper: {
        zIndex: 9999,
      },
    },
  },
}, ptBR);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
