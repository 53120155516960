import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreIcon from '@material-ui/icons/MoreVert';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';

import { Context } from '../../context/AuthContext';

import { ReactComponent as Logo } from '../../assets/logo_header.svg';
import { TypeContext } from '../filter-form/filter-form.component';

const Header = ({ open, handleDrawerOpen, handleDrawerClose }) => {
    const { handleLogout, user, setUserMetadata } = useContext(Context);


    useEffect(() => {
        (async () => {
            try {
                if (!user.nome)
                    setUserMetadata();
            } catch (e) {
                console.log(e);
            }
        })()
    }, [])

    const useStyles = makeStyles((theme) => ({
        appBar: {

            zIndex: theme.zIndex.drawer + 1,
            height: '64px'
        },

        menuButton: {
            marginRight: theme.spacing(2),
            height: '30px',

        },
        headerFont: {
            fontSize: '18px',
            fontWeight: '600',

        },
        grow: {
            flexGrow: 1,

        },
        sectionDesktop: {
            //height:'48px',
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        centerList: {
            listStyle: 'none',
            margin: theme.spacing(0, -3),
            '& > li': {
                display: 'inline',
                padding: theme.spacing(0, 3),
                '& > a': {
                    color: 'white',
                }
            },
        },
    }));

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const [anchorAdmMenu, setAnchorAdmMenu] = useState(null);
    const openAdmMenu = Boolean(anchorAdmMenu);
    const handleClickAdmMenu = (event) => {
        setAnchorAdmMenu(event.currentTarget);
    };
    const handleCloseAdmMenu = () => {
        setAnchorAdmMenu(null);
    };

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={'mobile-admin-submenu'}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Link to='/admin' style={{ color: '#707070', textDecoration: 'none' }}><MenuItem onClick={handleMenuClose}>Perfil</MenuItem></Link>
            <Link to='/upload' style={{ color: '#707070', textDecoration: 'none' }}><MenuItem onClick={handleMenuClose}>Upload CSV</MenuItem></Link>
        </Menu>
    );

    const mobileMenuId = 'menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleMobileMenuClose}>
                <IconButton color='#707070'>
                    <Badge color='secondary'>
                        <HomeIcon />
                    </Badge>
                </IconButton>
                <Link to='' style={{ color: '#707070', textDecoration: 'none' }}><p>Home</p></Link>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton color='#707070'>
                    <Badge color='secondary'>
                        <AccountCircle />
                    </Badge>
                </IconButton>
                <p style={{ color: '#707070', textDecoration: 'none' }}>Administrativo</p>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <IconButton
                    color='#707070'
                >
                    <AssessmentIcon />
                </IconButton>
                <Link to='/requests' style={{ color: '#707070', textDecoration: 'none' }}><p>Requisições</p></Link>
            </MenuItem>
            <MenuItem onClick={handleMobileMenuClose}>
                <IconButton color='#707070'>
                    <Badge color='secondary'>
                        <InfoIcon />
                    </Badge>
                </IconButton>
                <Link to='/about' style={{ color: '#707070', textDecoration: 'none' }}><p>Sobre</p></Link>
            </MenuItem>
        </Menu>
    );

    const classes = useStyles();
    const tipoRota = useContext(TypeContext);
    //console.log(`Tipo rota =${tipoRota.rota}`)

    if (tipoRota.rota === undefined) {
        tipoRota.rota = '/'
    }
    function comparaRota() {
        if (open & tipoRota.rota !== '/') {
            return handleDrawerClose
        }
        else if (!open & tipoRota.rota !== '/') {
            return handleDrawerOpen
        }
    }

    return (
        <div>

            <AppBar className={classes.appBar} position='fixed' elevation={0} >
                <Toolbar variant="regular" >
                    <IconButton
                        color='inherit'
                        aria-label='open or close drawer'
                        onClick={comparaRota()}
                        edge='start'
                        className={clsx(classes.menuButton, open)}

                    >
                        <MenuIcon fontSize='medium' />
                    </IconButton>
                    <Logo fontSize='medium' />

                    <div className={classes.grow} />

                    <div className={classes.sectionDesktop}>
                        <ul className={classes.centerList}>
                            <Typography component='li' className={classes.headerFont}><Link to={tipoRota.rota} >Início</Link></Typography>
                            <Typography component='li' className={classes.headerFont}><span style={{ cursor: 'pointer' }} onClick={handleClickAdmMenu}>Administrativo</span></Typography>
                            <Typography component='li' className={classes.headerFont}><Link to='/requests'>Requisições</Link></Typography>
                            <Typography component='li' className={classes.headerFont}><Link to='/about'>Sobre</Link></Typography>
                            <Typography component='li' className={classes.headerFont}><Link to={'/'} >Trocar Módulo</Link></Typography>
                        </ul>
                    </div>

                    <div className={classes.grow} />

                    <div className={classes.sectionDesktop} >
                        <Box display='flex' alignItems='center'  >
                            <IconButton aria-label='show user' color='inherit'>
                                <PersonIcon fontSize='medium' />
                            </IconButton>
                            <Typography component='span' className={classes.headerFont}>
                                {user.nome}
                            </Typography>
                        </Box>

                        <Box display='flex' alignItems='center' marginLeft='1rem'>
                            <IconButton aria-label='logout' color='inherit' onClick={handleLogout}>
                                <ExitToAppIcon fontSize='medium' />
                            </IconButton>
                        </Box>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label='show more'
                            aria-controls={mobileMenuId}
                            aria-haspopup='true'
                            onClick={handleMobileMenuOpen}
                            color='inherit'
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>

            <Menu
                id='adm-menu'
                anchorEl={anchorAdmMenu}
                open={openAdmMenu}
                onClose={handleCloseAdmMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{
                    style: {
                        transform: 'translateX(0) translateY(20px)',
                        borderRadius: '0 0 4px 4px',
                        minWidth: '130px'
                    }
                }}
            >
                <Link to='/admin'><MenuItem style={{ color: '#707070', textDecoration: 'none' }} onClick={handleCloseAdmMenu}>Perfil</MenuItem></Link>
                <Link to='/upload'><MenuItem style={{ color: '#707070', textDecoration: 'none' }} onClick={handleCloseAdmMenu}>Inserção de arquivos CSV</MenuItem></Link>
                <Link to='/device'><MenuItem style={{ color: '#707070', textDecoration: 'none' }} onClick={handleCloseAdmMenu}>Inserção de nova estação</MenuItem></Link>

            </Menu>

            {renderMobileMenu}
            {renderMenu}
        </div>

    );
}

export default Header;