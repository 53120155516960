import React, { useState, useContext } from 'react';
import CheckboxTree from 'react-checkbox-tree';

import { Context } from '../../context/MainContext';
import { TypeContext } from '../filter-form/filter-form.component';

import './react-checkbox-tree.scss';
import './treeview.styles.scss';

const Treeview = ({ handleRenderedMainPanel }) => {

    const { handleListInteraction, showAllMarkers, checked, setChecked, drawerNodes } = useContext(Context);

    const [expanded, setExpanded] = useState(
        []
    );
    const paragon=useContext(TypeContext);

    let novosNodos = []
    if(drawerNodes.length===1 & paragon.type !== 'Meteorológico'){
        novosNodos=drawerNodes
    }
    else if(paragon.type==='Meteorológico'){
        novosNodos=drawerNodes
    }


    const checkNode = checked => {
        setChecked(checked);
        if(checked.length <= 0) {
            handleRenderedMainPanel(null);
            showAllMarkers();
        } else {
            handleRenderedMainPanel('filter');
            handleListInteraction(checked);
        }
    }

    const expandNode = expanded => {
        setExpanded(expanded);
    }

    const clickNode = click => {
        
    }

    return (
        <div className='treeview-wrapper'>
            <CheckboxTree
                checked={checked}
                expanded={expanded}
                iconsClass="fa5"
                nodes={novosNodos}
                onCheck={checkNode}
                onExpand={expandNode}
                onClick={clickNode}
            />
           
        </div>
    );

}

export default Treeview;