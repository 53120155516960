import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import SelectInput from '../../select-input/select-input.component';

import api from '../../../api';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 3),
      maxWidth: '500px',
        '& > :first-child': {
            fontWeight: '500',
        },
        '& > :first-child + div': {
            margin: '0.8rem 0',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center',
        },
        '& > :first-child + div + div': {
            textAlign: 'justify',
        },
        '&:focus': {
            outline: 'none !important',
        },
        '& > img': {
            paddingTop: '1rem',
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const ProfileForm = () => {
    const classes = useStyles();

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [newPassword, setnewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [errorAlert, setErrorAlert] = useState('');
    const [successAlert, setSuccessAlert] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get('/usuario/metadados');
                setFullName(data.nome);
                setEmail(data.email);
                setPhone(data.telefone);
            } catch(e) {
                console.log(e)
            }
        })();
    }, []);

    const handleChangePassword = async e => {
        e.preventDefault();

        if(newPassword.localeCompare(newPasswordConfirmation) !== 0) {
            setErrorAlert('A senha precisa ser a mesma nos dois campos!');
            setFailureOpen(true);
            return;
        }

        try {
            await api.put(`/usuario/atualiza/${newPassword}`);
            setModalOpen(false);
            setSuccessAlert('Senha alterada com sucesso!');
            setSuccessOpen(true);
        } catch(e) {
            setErrorAlert('Verifique se os dois campos estão preenchidos corretamente!');
            setFailureOpen(true);
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
    
        setSuccessOpen(false);
        setFailureOpen(false);
    }

    const handleEditUser = async (event) => {
        setLoading(true);
        try {
            const data = {
                telefone: phone
            }
            await api.put('/usuario/atualiza/metadados', JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setLoading(false);
            setSuccessAlert('Usuário atualizado com sucesso!');
            setSuccessOpen(true);
        } catch(e) {
            setErrorAlert(e.response.data);
            setFailureOpen(true);
        }
    }

    return (
        <Box>
            <Modal
                aria-labelledby='modal-change-password'
                aria-describedby='modal-change-password-description'
                className={classes.modal}
                open={modalOpen}
                onClose={e => setModalOpen(false)}
                closeAfterTransition={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <Box className={classes.paper} display='flex' flexDirection='column' minWidth={'500px'}>
                        <div>Alterar senha</div>
                        <TextField
                            name='newPassword'
                            defaultValue=''
                            label='Nova senha'
                            placeholder='*****'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            type='password'
                            onChange={e => setnewPassword(e.target.value)}
                        />
                        <TextField
                            name='newPasswordConfirmation'
                            defaultValue=''
                            label='Confirmar nova senha'
                            placeholder='*****'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            type='password'
                            onChange={e => setNewPasswordConfirmation(e.target.value)}
                        />
                        <Box display='flex' justifyContent='flex-end' marginTop='0.5rem'>
                            <Button onClick={handleChangePassword} variant='contained' color='primary'>
                                CONFIRMAR
                            </Button>
                        </Box>

                        <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity='error'>
                                {errorAlert}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Fade>
            </Modal>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        id='fullname'
                        label='Nome completo'
                        placeholder='Exemplo de nome'
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin='normal'
                        variant='outlined'
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField 
                        id='email'
                        label='Email'
                        placeholder='exemplo@gmail.com'
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin='normal'
                        variant='outlined'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <TextField 
                        id='phone'
                        label='Telefone'
                        placeholder='(00) 00000-0000'
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin='normal'
                        variant='outlined'
                        value={phone}
                        onChange={e => setPhone(e.target.value)}
                    />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <SelectInput
                        label='idiom'
                        labelTitle='Idioma'
                        values={[{id: 'pt-br', name: 'PT-BR'}]}
                        defaultValue='pt-br'
                    />
                </Grid>
            </Grid>

            <div className='button-wrapper multiples'>
                <Button variant='contained' color='primary' onClick={e => setModalOpen(true)}>
                    ALTERAR SENHA
                </Button>
                <Button onClick={handleEditUser} variant='contained' color='primary'>
                    SALVAR
                </Button>
            </div>

            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    {successAlert}
                </Alert>
            </Snackbar>

            <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='error'>
                    {errorAlert}
                </Alert>
            </Snackbar>

            <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='info'>
                    <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                </Alert>
            </Snackbar>

        </Box>
    );
}

export default ProfileForm;