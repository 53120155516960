import React, { useState } from 'react';
import clsx from 'clsx';
import CheckboxTree from 'react-checkbox-tree';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

import '../treeview/react-checkbox-tree.scss';
import './checkbox-field.styles.scss';

const CheckboxField = ({ stationType, setStationType }) => {
    const nodes = [
        {
            value: 'waterquality',
            label: 'Qualidade de Água',
            children: [
                {
                    value: 'Qualidade de Água/89',
                    label: 'Biológico',
                },
                {
                    value: 'Qualidade de Água/90',
                    label: 'Físico/Químico',
                },
            ],
        },
        {
            value: 'Águas Subterrâneas',
            label: 'Águas Subterrâneas',
        },
        {
            value: 'Sedimentos',
            label: 'Sedimentos',
        },
        {
            value: 'Meteorológico',
            label: 'Meteorológico',
        },
    ];

    const [open, setOpen] = useState(false);

    const [expanded, setExpanded] = useState(['waterquality']);

    return (
        <div className='checkbox-field-wrapper'>
            <Typography component='span'>Tipo da estação</Typography>
            <div className={clsx('tree-wrapper', { ['open'] : open })}>
                <IconButton
                    color='inherit'
                    aria-label='open or close filter'
                    onClick={() => open ? setOpen(false) : setOpen(true)}
                    className='expand'
                >
                    <ExpandMoreIcon fontSize='small' className={clsx('expand-icon', { ['open'] : open })} />
                </IconButton>
                
                <CheckboxTree
                    checked={stationType}
                    expanded={expanded}
                    iconsClass="fa5"
                    nodes={nodes}
                    onCheck={checked => setStationType(checked)}
                    onExpand={expanded => setExpanded(expanded)}
                    icons={{
                        check: <span className="rct-icon rct-icon-check" />,
                        uncheck: <span className="rct-icon rct-icon-uncheck" />,
                        halfCheck: <span className="rct-icon rct-icon-half-check" />,
                        expandClose: <span className="rct-icon rct-icon-expand-close" />,
                        expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                        expandAll: <span className="rct-icon rct-icon-expand-all" />,
                        collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                        parentClose: <span className="rct-icon rct-icon-parent-close" />,
                        parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                        leaf: <span className="rct-icon rct-icon-leaf" />,
                    }}
                />
            </div>
        </div>
    );

}

export default CheckboxField;