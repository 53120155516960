import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import {ReactComponent as Logo} from '../../assets/logo.svg';
import './login-form.styles.scss';
import iconLogin from '../../assets/icon-login.png';

import api from '../../api';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: '2px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 3, 3),
      '& > :first-child': {
        fontWeight: '500',
      },
      '& > :first-child + div': {
          margin: '0.8rem 0 0.3rem',
      }
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const LoginForm = ({ handleLogin }) => {
    const form = useRef(null);
    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const [forgotPassword, setForgotPassword] = useState('');
    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [failureMessage, setfailureMessage] = useState('Erro ao realizar a requisição.');
    const [loading, setLoading] = useState(false);

    const submit = async e => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData(form.current).entries();
        let dataObject = {};

        for (const [key, value]  of data) {
            dataObject[key] = value;
        }

        const logged = await handleLogin(dataObject);
        if(!logged) {
            setLoading(false);
            setfailureMessage('E-mail ou senha inválidos!')
            setFailureOpen(true);
        }
    }

    const handleForgotPassword = async e => {
        e.preventDefault();

        setLoading(true);
        const data = {email: forgotPassword}
        const headers = {
            'Content-Type': 'application/json'
        }
        try {
            await api.post('/forgot', JSON.stringify(data), {
                headers: headers
            });
            setLoading(false);
            setSuccessOpen(true);
        } catch(e) {
            setfailureMessage('Erro ao realizar a requisição.');
            setLoading(false);
            setFailureOpen(true);
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    }

    return (
        <div>
        <Box className='boxLogo'> <Logo stroke='black'  className='logoclass'/></Box>
        <Box className='login-form'>
            <Modal
                aria-labelledby='modal-forgot-password'
                aria-describedby='modal-forgot-password-description'
                className={classes.modal}
                open={modalOpen}
                onClose={e => setModalOpen(false)}
                closeAfterTransition={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen} >
                    <Box className={classes.paper} display='flex' flexDirection='column'>
                        <div>Esqueci minha senha</div>
                        <div>Confira seu e-mail para receber a nova senha de acesso ao sistema.</div>
                        <TextField
                            name='forgot_password'
                            defaultValue=''
                            label='E-mail'
                            placeholder='exemplo@exemplo.com'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            onChange={e => setForgotPassword(e.target.value)}
                        />
                        <Box display='flex' justifyContent='flex-end' marginTop='0.5rem'>
                            <Button onClick={handleForgotPassword} variant='contained' color='primary'>
                                CONFIRMAR
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
           
            <form ref={form} onSubmit={submit} noValidate>
                <Box className='inputs'>
                    <TextField
                        name='email'
                        defaultValue=''
                        label='E-mail'
                        placeholder='exemplo@exemplo.com'
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin='normal'
                        variant='outlined'
                    />
                    <TextField
                        name='senha'
                        defaultValue=''
                        label='Senha'
                        placeholder='******'
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin='normal'
                        variant='outlined'
                        type='password'
                    />
                    <Box className='options'>
                        { /* <FormControlLabel
                            control={
                            <Checkbox
                                name='remember'
                                color='primary'
                            />
                            }
                            label='Lembrar de mim'
                        /> */ }
                        <a href='#'>
                            <Typography onClick={e => setModalOpen(true)} component='span' color='primary'>
                                Esqueci minha senha &#10095;
                            </Typography>
                        </a>
                    </Box>
                </Box>
                <Box className='button' >
                    <Button type='submit' variant='contained' color='primary' fullWidth  >
                        LOGIN <img src={iconLogin} height ="15px" width="15px" color='#FFFFFF' />
                    </Button>
                </Box>
            </form>
            <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='success'>
                    Nova senha enviada com sucesso!
                </Alert>
            </Snackbar>

            <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='error'>
                    {failureMessage}
                </Alert>
            </Snackbar>

            <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={handleAlertClose} severity='info'>
                    <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                </Alert>
            </Snackbar>
        </Box>
        </div>
    );
}

export default LoginForm;