import React, { useState, useEffect } from 'react';

import TabsPanel from '../tabs-panel/tabs-panel.component';
import NewDevice from './new-device/new-device.component';
import DeviceList from './device-list/device-list.component';

import api from '../../api';
import EditDevice from './edit-device/edit-device.component';
import { useLocation } from 'react-router-dom';

const DeviceManager = () => {
    const [userMaxLevel, setUserMaxLevel] = useState(0);
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const [tab, setTab] = useState(queryParameters.get("search")?1:0);    
    const [editDevice, setEditDevice] = useState({
        id: 0,
        name: '',
    });

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get('/usuario/metadados');
                const maxLevelAccess = data.vinculos.map(item => item.perfil.levelaccess).reduce(function(a, b) {
                    return Math.max(a, b);
                });
                setUserMaxLevel(maxLevelAccess);
            } catch(e) {
                // console.log(e)
            }
        })();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    }


    // console.log(editDevice)

    return (
        <TabsPanel
            tabsComponents={[
                <NewDevice title='Nova estação' show />,
                <DeviceList 
                    title='Lista de Estações' 
                    show={userMaxLevel === 10 ? true : false} 
                    handleChangeTab={handleChangeTab}
                    setEditDevice={setEditDevice}
                    userMaxLevel={userMaxLevel}
                />,
                <EditDevice title='Atualizar estação' editDevice={editDevice} show={editDevice.id !== 0 ? true : false} />
            ]}
            handleChangeTab={handleChangeTab}
            tab={tab}
        />
    )

}

export default DeviceManager;