import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import api from '../../../../api';

import EditIcon from '@material-ui/icons/Edit';

import {ReactComponent as CheckIcon} from '../../../../assets/upload/checkbox-circle-line.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/upload/close-circle-line.svg';
import {ReactComponent as DeleteIcon} from '../../../../assets/admin/delete-bin-5-fill.svg';
import { Backdrop, Box, Button, Fade, IconButton, Modal, Tooltip, Typography } from '@material-ui/core';

const headCells = [
    { id: 'nome', align: 'left', numeric: false, disablePadding: false, label: 'Nome da estação' },
    { id: 'ativo', align: 'center', numeric: false, disablePadding: false, label: 'Status' },   
    { id: 'dataDeCriacao', align: 'left', numeric: false, disablePadding: false, label: 'Data de Criação' },
    // { id: 'institution', align: 'left', numeric: false, disablePadding: false, label: 'Instituição' },
    // { id: 'areainstitution', align: 'left', numeric: false, disablePadding: false, label: 'Área da Instituição' },
    // { id: 'areaapplication', align: 'left', numeric: false, disablePadding: false, label: 'Área da Aplicação' },
    { id: 'actions', align: 'center', numeric: false, disablePadding: false, label: 'Ações' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow className={classes.tableHeadBorderless}>
            {headCells.map((headCell) => (
                <TableCell
                key={headCell.id}
                align={headCell.align === 'center' ? 'center' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}
                >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                    ) : null}
                </TableSortLabel>
                </TableCell>
            ))}
            </TableRow>
        </TableHead>
    );
};

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-of-type > td': {
            borderBottom: '0 !important',
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHeadBorderless: {
        '&:last-of-type > th': {
            borderBottom: '0 !important',
        },
    },
    statusOpen: {
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            '-moz-border-radius': '7.5px',
            '-webkit-border-radius': '7.5px',
            borderRadius: '7.5px',
            backgroundColor: '#4CAF50',
            position: 'absolute',
            left: '50%',
            marginLeft: '-16px',
            bottom: '24px',
            cursor: 'pointer',
        },
    },
    statusClosed: {
        '&::before': {
            content: '""',
            display: 'inline-block',
            width: '16px',
            height: '16px',
            '-moz-border-radius': '7.5px',
            '-webkit-border-radius': '7.5px',
            borderRadius: '7.5px',
            backgroundColor: '#F44336',
            position: 'absolute',
            left: '50%',
            marginLeft: '-16px',
            bottom: '24px',
            cursor: 'pointer',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    paperModal: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '2px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 3, 3),
        maxWidth: '500px',
        '& > :first-child': {
            fontSize: 16,
            fontWeight: '500',
        },
        '& > :first-child + div': {
            margin: '0.8rem 0',
            fontWeight: '500',
            width: '100%',
            textAlign: 'center',
        },
        '& > :first-child + div + div': {
            textAlign: 'justify',
        },
        '&:focus': {
            outline: 'none !important',
        },
        '& > img': {
            paddingTop: '1rem',
        },
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const DeviceListTable = props => {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [dispid, setDispid] = useState({});
    const [orderBy, setOrderBy] = useState('initial_date');
    const [modalOpen, setModalOpen] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [failureOpen, setFailureOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState('');
    const [alertError, setAlertError] = useState('Por favor, verifique se todos os campos estão preenchidos corretamente.');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway')
          return;
        
        setSuccessOpen(false);
        setFailureOpen(false);
        setLoading(false);
    };

    const handleOpenModal = (event, item) => {
        // console.log(item);
        setDispid(item);
        setModalOpen(true);
    }

    const handleDelete = async () => {
        setModalOpen(false);
        setLoading(true);
       
        try { 
            await api.delete(`/dispositivo/delete?id=${dispid.id}&application_area_id=${dispid.application_area_id}&area_institution_id=${dispid.area_institution_id}&institution_id=${dispid.institution_id}&ecosystem_id=sima`);
            setLoading(false);
            await api.get('/usuario/atualiza/permissoes')
            setAlertSuccess('Estação excluida com sucesso!');
            setSuccessOpen(true);
            window.location.reload();
        } catch(e) {
            setLoading(false);
            setAlertError('Erro ao excluir o estação.');
            setFailureOpen(true);
        }
    }

    return (
        <div>
            <Modal
                className={classes.modal}
                open={modalOpen}
                onClose={e => setModalOpen(false)}
                closeAfterTransition={false}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    <Box className={classes.paperModal} display='flex' flexDirection='column' minWidth={'500px'}>
                        <div>Excluir estação</div>
                        <Typography>Tem certeza que deseja excluir essa estação?</Typography><br />
                        <Box display='flex' justifyContent='flex-end' marginTop='0.5rem'>
                            <Button onClick={e => setModalOpen(false)} variant='contained' color='secondary' style={{ marginRight: 8}}>
                                CANCELAR
                            </Button>
                            <Button onClick={handleDelete} variant='contained' color='primary'>
                                CONFIRMAR
                            </Button>
                        </Box>

                        <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                            <Alert onClose={handleAlertClose} severity='error'>
                                {alertError}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Fade>
            </Modal>
            <div className={classes.root}>
                <TableContainer components={{ Container: props => <Paper {...props} elevation={0}/> }}>
                    <Table aria-label='panel table' className={'table-container'}>
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                        {props.data.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                    <StyledTableRow
                                        tabIndex={-1}
                                        key={row.id}
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        <TableCell>{row.nome}</TableCell>
                                        <TableCell align='center'>
                                            {
                                                !row.ativo ? 
                                                <CheckIcon fill='currentColor' style={{color: '#00CCC0'}} /> : 
                                                <CloseIcon fill='currentColor' style={{color: '#f44336'}} />
                                            }
                                        </TableCell>
                                        <TableCell>{row.dataDeCriacao}</TableCell>
                                        <TableCell align='center'>
                                            <Tooltip title="Atualizar estação">
                                                <IconButton aria-label="edit">
                                                    <EditIcon
                                                        fill='currentColor'
                                                        style={{
                                                            color: '#00CCC0',
                                                        }}
                                                        onClick={e => {
                                                            props.setEditDevice({
                                                                dispid: row.dispid
                                                            })
                                                            props.handleChangeTab(e, 2);
                                                        }}
                                                    />  
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Remover estação">
                                                <IconButton aria-label="delete">
                                                    <DeleteIcon
                                                        fill='currentColor'
                                                        style={{
                                                            color: '#00CCC0',
                                                        }}
                                                        onClick={e => handleOpenModal(e, row.dispid)}
                                                    />
                                                </IconButton>                                   
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={props.count}
                    rowsPerPage={15}
                    page={props.page}
                    onChangePage={props.handleChangePage}
                    rowsPerPageOptions={[5]}
                />

                <Snackbar open={successOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleAlertClose} severity='success'>
                        {alertSuccess}
                    </Alert>
                </Snackbar>

                <Snackbar open={failureOpen} autoHideDuration={5000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleAlertClose} severity='error'>
                        {alertError}
                    </Alert>
                </Snackbar>

                <Snackbar open={loading} autoHideDuration={null} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert onClose={handleAlertClose} severity='info'>
                        <CircularProgress size={12} color='secondary' /> &nbsp; Carregando...
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
};

export default DeviceListTable;