import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import history from '../../../history';

import DeviceListTable from './device-list-table/device-list-table.component';
import Loading from '../../loading/loading.component';

// import deviceList from './list-devices.json';

import api from '../../../api';
import { useLocation } from 'react-router-dom';

const DeviceList = (props) => {
    const [loadingData, setLoadingData] = useState(true);
    const [nameSearch, setNameSearch] = useState('');

    const [page, setPage] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search)

    useEffect(() => {
        (async () => {
            if(queryParameters.get("search") !== null){
                const deviceList = await api.get(`/dispositivo/buscar/nome?search=${queryParameters.get("search")}`);
                updateTableData(deviceList.data.content);
                console.log(deviceList.data.content.length)
                setCount(deviceList.data.content.length);
            } else {
                const deviceList = await api.get('/dispositivo/todos/atrelados');
                updateTableData(deviceList.data.content);
                console.log(deviceList.data.content.length)
                setCount(deviceList.data.content.length);
            }
            // console.log(deviceList.data.content)
            setLoadingData(false);
        })();
    }, []);

    const updateTableData = (data) => {
        const formattedData = data.map(item => ({
            id: item.dispid.id,
            status: item.status,
            nome: item.nome,
            dispid: item.dispid,
            // institution: item.dispid.institution_id,
            dataDeCriacao: moment(item.dataDeCriacao).format('DD/MM/YYYY'),
            // institutionArea: item.dispid.area_institution_id,
            // applicationArea: item.dispid.application_area_id,
        }));
        setTableData(formattedData);
    }

    const handleChangePage = async (event, newPage) => {
        try {
            const request = await api.get(`/dispositivo/todos/atrelados?pageNo=${newPage}&pageSize=5`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(newPage);
        } catch(e) {
            console.log(e);
        }
    }

    const handleTableSearch = async (event) => {
        event.preventDefault();
        try {
            const request = await api.get(`/dispositivo/buscar/nome?search=${nameSearch}`);
            updateTableData(request.data.content);
            setCount(request.data.totalElements);
            setPage(0);
            history.push({
                pathname: '/device',
                search: `?search=${nameSearch}`
              });
        } catch(e) {
            console.log(e);
        }
    }

    return (
        <div>
            <form onSubmit={handleTableSearch}>
                <Grid container spacing={2} style={{marginBottom: '0.313rem'}}>
                    <Grid item xs={12} sm={5}>
                        <TextField 
                            id='file'
                            label='Buscar estação'
                            placeholder='Nome'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin='normal'
                            variant='outlined'
                            value={nameSearch}
                            onChange={e => setNameSearch(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                        <Box display='flex' height='100%' justifyContent='space-between' alignItems='center'>
                            <Button type='submit' variant='contained' color='primary' style={{maxHeight: '2.375rem'}} >
                                BUSCAR
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>

            {
                loadingData
                ?
                (<Loading />)
                :
                <DeviceListTable 
                    page={page}
                    rowsPerPage={10}
                    count={count}
                    handleChangePage={handleChangePage}
                    data={tableData}
                    userMaxLevel={props.userMaxLevel}
                    handleChangeTab={props.handleChangeTab}
                    setEditDevice={props.setEditDevice}
                />
            }
        </div>
    )
}

export default DeviceList;